import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { BookingInfo, HotelProduct } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-hotel-detail-box',
  templateUrl: './hotel-detail-box.component.html',
  styleUrls: ['./hotel-detail-box.component.scss']
})
export class HotelDetailBoxComponent implements OnInit {
  @Input() hotelProduct: HotelProduct;
  @Input() bookingInfo: BookingInfo;
  startDate;
  endDate;
  hotelBooking;
  counter = Array;
  amenitiesWithoutDescription;

  constructor() {}

  ngOnInit() {
    if (this.bookingInfo) {
      this.hotelBooking = this.bookingInfo.transactionInfos.find(
        transaction => transaction.supplierType === 'HOTEL'
      );
      if (this.hotelProduct.rooms[0].ratePlans[0].cancelPenalties[0]) {
        this.startDate = this.hotelProduct.rooms[0].ratePlans[0].cancelPenalties[0].startDate.split(
          ' '
        )[0];
        this.endDate = this.hotelProduct.rooms[0].ratePlans[0].cancelPenalties[0].endDate.split(
          ' '
        )[0];
      }
      this.amenitiesWithoutDescription = this.hotelProduct.rooms[0].ratePlans[0].amenities.filter(
        amenity => amenity.id !== 'rate_description'
      );
    }
  }

  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}
@Pipe({
  name: 'numberNight'
})
export class NumberNight implements PipeTransform {
  transform(departureDate: string, returnDate?: string): number {
    let numbDate = 0;
    if (departureDate && returnDate) {
      numbDate = this.datediff(
        this.parseDate(departureDate),
        this.parseDate(returnDate)
      );
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }
  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
}
