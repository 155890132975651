import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { SearchBestRatesResult } from '@gtd/b2c-client';
import * as moment from 'moment';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';

@Component({
  selector: 'gtd-hotel-search-result-item-map',
  templateUrl: './hotel-search-result-item-map.component.html',
  styleUrls: ['./hotel-search-result-item-map.component.scss']
})
export class HotelSearchResultItemMapComponent implements OnInit {
  @Input() hotelItems: SearchBestRatesResult;
  @Input() searchParams: any;
  @Input() params: string;
  @Input() flightTicketPrice: number;
  @Input() adultTicketPrice: number;
  @Input() searchId: string;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  priceOnePerson = 0;
  numberNight;
  counter = Array;

  constructor(
    private serializer: UrlSerializer,
    private router: Router,
    private _comboSelectedService: ComboSelectedService
  ) {}

  ngOnInit() {
    this.numberNight = this.countNumberDate(
      moment(this.searchParams.fromDate).format('YYYY-MM-DD'),
      moment(this.searchParams.toDate).format('YYYY-MM-DD')
    );
  }

  viewDetailItem(value, data) {
    let params = {
      locationCode: data.searchParams.location.name,
      locationName: data.searchParams.location.name,
      searchType: data.searchParams.location.searchType,
      roomsString: data.searchParams.roomsString,
      checkIn: data.searchParams.fromDate,
      checkOut: data.searchParams.toDate,
      searchId: data.searchId,
      propertyId: value.propertyId,
      supplier: value.supplier,
      paxInfos: data.searchParams.rooms
    };
    this.searchParams = {
      ...this.searchParams,
      searchId: data.searchId,
      propertyId: value.propertyId,
      location: {
        ...this.searchParams.location,
        supplier: value.supplier
      }
    };
    this._comboSelectedService.selectHotel(value);
    localStorage.setItem('hotelBooking', JSON.stringify(value));
    this.router.navigate(['/combo/hotel/detail'], {
      queryParams: {
        params: btoa(encodeURIComponent(JSON.stringify(this.searchParams)))
      }
    });
  }
  countPriceOnePerson(totalPrice: number) {
    const numberPerson = this.searchParams.roomsArrs.reduce((arr, cur) => {
      return arr + cur.adultQuantity + cur.infantCount + cur.childQuantity;
    }, 0);
    return Math.ceil(totalPrice / numberPerson);
  }
  countNumberDate(departureDate: string, returnDate?: string): number {
    let numbDate;
    if (departureDate && returnDate) {
      numbDate = this.datediff(
        this.parseDate(departureDate),
        this.parseDate(returnDate)
      );
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('-');
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }
  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  hotelItemHover(itemHotel, index: number) {
    this.changed.emit({
      itemHotel: itemHotel,
      index: index,
      type: 'hover'
    });
  }
  hotelItemHoverOut(itemHotel, index: number) {
    this.changed.emit({
      itemHotel: itemHotel,
      index: index,
      type: 'out'
    });
  }

  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}
