import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Router} from "@angular/router";
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';

@Component({
  selector: 'gtd-combo-booking',
  templateUrl: './combo-booking.component.html',
  styleUrls: ['./combo-booking.component.scss']
})
export class ComboBookingComponent implements OnInit {
  @Input() flightBooking;
  @Input() hotelBooking;
  @Input() params;
  @Input() searchParams;
  @Output() scrollToSection: EventEmitter<any> = new EventEmitter<any>();
  hotelBookingPrice: number;
  flightBookingPrice: number;
  priceOnePerson: number;
  constructor(
    private router: Router,
    private _comboSelectedService: ComboSelectedService
  ) {}

  ngOnInit() {
    this.priceCalculator();
  }
  priceCalculator() {
    this.flightBookingPrice = this.flightBooking.reduce((arr, cur) => {
      return (
        arr +
        cur.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare
          .amount
      );
    }, 0);

    this.hotelBookingPrice = this.hotelBooking.totalPrice;
    const numberPerson = JSON.parse(this.searchParams.rooms).reduce(
      (arr, cur) => {
        return arr + cur.adultQuantity + cur.infantCount + cur.childQuantity;
      },
      0
    );

    const adultTicketPrice = this.flightBooking.reduce((arr, cur) => {
      return (
        arr +
        cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .baseFare.amount +
        (cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .comboMarkup
          ? cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .comboMarkup.amount
          : 0) +
        cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .serviceTax.amount
      );
    }, 0);

    this.priceOnePerson =
      Math.ceil(this.hotelBookingPrice / numberPerson) + adultTicketPrice;
  }
  scrollToRooms() {
    this.scrollToSection.emit('detail');
  }

  getDestination(item: any, type: string, idx: number): any {
    if(item.itemFlight.flightType === 'INTERNATIONAL') {
      let originDestinationOptions = item.pricedItinerary.originDestinationOptions.filter(
        (originDestinationOption: any) => originDestinationOption.flightDirection === (idx===0? 'D': 'R')
      )
      if (!originDestinationOptions.length) return null;
      return {
        city: type === 'destination' ? originDestinationOptions[0].destinationCity : originDestinationOptions[0].originCity,
        city2: type === 'destination' ? originDestinationOptions[0].destinationCity : originDestinationOptions[0].originCity,
        code: type === 'destination' ? originDestinationOptions[0].destinationLocationCode : originDestinationOptions[0].originLocationCode,
      };
    } else {
      const flight = item.itemFlight;
      return {
        city: type === 'destination' ? flight.destinationCity : flight.originCity,
        city2: type === 'destination' ? flight.destinationCity : flight.originCity,
        code: type === 'destination' ? flight.destinationLocationCode : flight.originLocationCode,
      };
    }
  }



  resetFlight(type: 'from' | 'to') {
    const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));

    this._comboSelectedService.removeFlight(
      type,
      flightBooking[type === 'from' ? 0 : 1].pricedItinerary
        .airItineraryPricingInfo.itinTotalFare.totalFare.amount
    );
    this.router.navigate(['/combo/flight'], {
      queryParams: {
        params: this.params
      }
    });
  }

  getDateTimeFlight(itemFlight: any, type: 'D' | 'R') {
    if(itemFlight.pricedItineraries && itemFlight.pricedItineraries.length &&
      itemFlight.pricedItineraries[0].originDestinationOptions &&
      itemFlight.pricedItineraries[0].originDestinationOptions.length
    ) {
      let itemFlightType = itemFlight.pricedItineraries[0].originDestinationOptions.filter(
        destination => destination.flightDirection === type
      )
      return itemFlightType.length ? itemFlightType[0].originDateTime: null
    }
  }
}
