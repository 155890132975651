import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BoxFlightBookingInfoComponent} from './box-flight-booking-info/box-flight-booking-info.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { BoxItemDomesticComponent } from './box-flight-booking-info/box-item-domestic/box-item-domestic.component';
import {TranslateModule} from "@ngx-translate/core";
import {AirlinesModule} from "../../../airlines/src/lib/airlines.module";
import {LoadingModule} from "@gtd/components/loading";
import {MatButtonModule, MatDialogModule, MatIconModule, MatTabsModule} from "@angular/material";
import {FlightDetailDialogComponent} from "./flight-detail-dialog/flight-detail-dialog.component";
import {AirTicketsResourceService} from "@gtd/b2c-client";
import { BoxItemIntComponent } from './box-flight-booking-info/box-item-int/box-item-int.component';
import {FlightIntDetailDialogComponent} from "./flight-int-detail-dialog/flight-int-detail-dialog.component";
import { ComponentsGiftServiceModule } from '@gtd/components/gift-service';
import { PipesGetPnrModule } from '@gtd/pipes/get-pnr';
library.add(
  faChevronRight,
);

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FontAwesomeModule,
    TranslateModule,
    AirlinesModule,
    LoadingModule,
    MatTabsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    ComponentsGiftServiceModule,
    PipesGetPnrModule
  ],
  declarations: [
    BoxFlightBookingInfoComponent,
    BoxItemDomesticComponent,
    FlightDetailDialogComponent,
    BoxItemIntComponent,
    FlightIntDetailDialogComponent
  ],
  exports: [BoxFlightBookingInfoComponent],
  providers: [AirTicketsResourceService],
  entryComponents: [FlightDetailDialogComponent, FlightIntDetailDialogComponent]
})
export class BoxFlightBookingInfoModule {}
