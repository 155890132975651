import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SEARCHBESTRATES_FEATURE_KEY,
  SearchBestRatesState
} from './search-best-rates.reducer';

// Lookup the 'SearchBestRates' feature state managed by NgRx
const getSearchBestRatesState = createFeatureSelector<SearchBestRatesState>(
  SEARCHBESTRATES_FEATURE_KEY
);

const getLoaded = createSelector(
  getSearchBestRatesState,
  (state: SearchBestRatesState) => state.loaded
);
const getError = createSelector(
  getSearchBestRatesState,
  (state: SearchBestRatesState) => state.error
);

const getAllSearchBestRates = createSelector(
  getSearchBestRatesState,
  getLoaded,
  (state: SearchBestRatesState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getSearchBestRatesState,
  (state: SearchBestRatesState) => state.selectedId
);
const getSelectedSearchBestRates = createSelector(
  getAllSearchBestRates,
  getSelectedId,
  (searchBestRates, id) => {
    return searchBestRates ? Object.assign({}, searchBestRates) : undefined;
  }
);

export const searchBestRatesQuery = {
  getLoaded,
  getError,
  getAllSearchBestRates,
  getSelectedSearchBestRates
};
