import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { ComboSearchBestRatesFacade } from '../../../../../state/search-best-rates/search-best-rates.facade';
import { AgmInfoWindow, MouseEvent } from '@agm/core';
import * as mapTypes from '@agm/core/services/google-maps-types';
import { Subscription } from 'rxjs';
import { Animation } from '@agm/core/directives/marker';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { FilterAvailableState } from '../../../../../state/filter-available/filter-available.reducer';
import { FilterAvailableFacade } from '../../../../../state/filter-available/filter-available.facade';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { FilterOptionsFacade } from '../../../../../state/filter-options/filter-options.facade';
import { isPlatformBrowser, Location } from '@angular/common';
import { fadeInOut } from '@gtd/helpers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FilterPanelMobileComponent } from '../filter-panel-mobile/filter-panel-mobile.component';
import { OpenSheetSwipeHotelComponent } from './open-sheet-swipe-hotel/open-sheet-swipe-hotel.component';
import { SearchBestRatesResult } from '@gtd/b2c-client';
import * as moment from 'moment';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';

@Component({
  selector: 'gtd-maps-panel',
  templateUrl: './maps-panel.component.html',
  styleUrls: ['./maps-panel.component.scss'],
  animations: [fadeInOut]
})
export class MapsPanelComponent implements OnInit, OnDestroy {
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  allSearchLoaded$ = this.searchBestRatesFacade.loaded$;
  allSearchBestRates$ = this.searchBestRatesFacade.allSearchBestRates$;
  flightBooking$ = this._comboSelectedService.flightListSelected$;
  totalFlightPrice$ = this._comboSelectedService.totalPrice$;
  isLoading: boolean;
  currentIW: AgmInfoWindow;
  previousIW: AgmInfoWindow;
  counter = Array;
  lastOpen: any;
  currentLang: 'vi' | 'en';
  currentFilter: FilterAvailableState;
  searchParams: any;
  openMarker: any;
  totalPage: number;
  isHideListItem: boolean;
  searchId: string;
  allSearchBestRates: SearchBestRatesResult;
  params: string;
  adultTicketPrice = 0;

  private subscriptions: Subscription = new Subscription();

  markers: marker[] = [];
  zoom: number = 12;
  lat: number = 10.76329;
  lng: number = 106.6784;
  fontFamilyMap: string = 'custom-label-price';

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
    private serializer: UrlSerializer,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private searchBestRatesFacade: ComboSearchBestRatesFacade,
    private dialog: MatDialog,
    private translate: TranslateService,
    private filterAvailableFacade: FilterAvailableFacade,
    private _bottomSheet: MatBottomSheet,
    private filterOptionsFacade: FilterOptionsFacade,
    private _comboSelectedService: ComboSelectedService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    type OwnerNameLang = 'vi' | 'en';
    this.currentLang = this.translate.currentLang as OwnerNameLang;
    this.currentIW = null;
    this.previousIW = null;
    this.activatedRoute.queryParams.subscribe(param => {
      this.params = param.params;
      this._handleQueryFromParams(
        JSON.parse(decodeURIComponent(atob(param.params)))
      );
    });
    setTimeout(() => {
      this.filterOptionsFacade.loadAll(this.currentLang);
    }, 500);
  }

  openFilterBottomSheet(): void {
    this._bottomSheet.open(FilterPanelMobileComponent, {
      data: this.currentFilter ? this.currentFilter : null
    });
  }

  openListHotelBottomSheet(): void {
    this._bottomSheet.open(OpenSheetSwipeHotelComponent, {
      data: {
        currentFilter: this.currentFilter ? this.currentFilter : null,
        searchParams: this.searchParams,
        totalPage: this.totalPage
      }
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.flightBooking$.subscribe(flightBooking => {
        if (flightBooking) {
          this.adultTicketPrice = flightBooking.reduce((arr, cur) => {
            return (
              arr +
              cur.pricedItinerary.airItineraryPricingInfo.adultFare
                .passengerFare.baseFare.amount +
              (cur.pricedItinerary.airItineraryPricingInfo.adultFare
                .passengerFare.comboMarkup
                ? cur.pricedItinerary.airItineraryPricingInfo.adultFare
                    .passengerFare.comboMarkup.amount
                : 0) +
              cur.pricedItinerary.airItineraryPricingInfo.adultFare
                .passengerFare.serviceTax.amount
            );
          }, 0);
        } else {
          this.router.navigate(['/combo/hotel'], {
            queryParams: {
              params: this.params
            }
          });
        }
      })
    );
    this.subscriptions.add(
      this.filterAvailableFacade.currentFilter$.subscribe(
        (currentFilter: FilterAvailableState) => {
          if (currentFilter) {
            this.currentFilter = currentFilter;
            this.isLoading = true;
            this.currentIW = null;
            this.previousIW = null;
            this.searchBestRatesFacade.loadAll({
              searchCode: this.searchParams.location.searchCode,
              searchType: this.searchParams.location.searchType,
              supplier: this.searchParams.location.supplier,
              language: currentFilter.language
                ? currentFilter.language
                : this.currentLang,
              currency: 'VND',
              checkIn: moment(this.searchParams.fromDate).format('YYYY-MM-DD'),
              checkOut: moment(this.searchParams.toDate).format('YYYY-MM-DD'),
              paxInfos: this.searchParams.roomsString,
              filterHotelName: currentFilter.filterHotelName,
              filterHotelCategories: currentFilter.filterHotelCategories,
              filterFromPrice: currentFilter.filterPrice
                ? currentFilter.filterPrice.fromPrice
                : null,
              filterToPrice: currentFilter.filterPrice
                ? currentFilter.filterPrice.toPrice
                : null,
              filterStarRating: currentFilter.filterStarRating,
              filterFromGuestRating: currentFilter.filterGuestRating
                ? currentFilter.filterGuestRating.from
                : null,
              filterToGuestRating: currentFilter.filterGuestRating
                ? currentFilter.filterGuestRating.to
                : null,
              filterPropertyAmenities: currentFilter.filterPropertyAmenities,
              filterRoomAmenities: currentFilter.filterRoomAmenities,
              filterRoomViews: currentFilter.filterRoomViews,
              filterThemes: currentFilter.filterThemes,
              filterMealPlans: currentFilter.filterMealPlans,
              sortField: currentFilter.sort
                ? currentFilter.sort.sortField
                : null,
              sortOrder: currentFilter.sort
                ? currentFilter.sort.sortOrder
                : null,
              pageNumber: currentFilter.pageNumber
                ? currentFilter.pageNumber
                : 0,
              pageSize: currentFilter.pageSize ? currentFilter.pageSize : 15
            });
          }
        }
      )
    );
    this.subscriptions.add(
      this.allSearchBestRates$.subscribe(allSearchBestRates => {
        if (allSearchBestRates) {
          if (allSearchBestRates) {
            this.allSearchBestRates = allSearchBestRates;
            this.searchId = allSearchBestRates.searchId;
            this.totalPage = allSearchBestRates.pageResult.totalPage;
            this.markers = [];
            this.isLoading = false;
            allSearchBestRates.propertyAvailable.forEach(itemHotel => {
              let params = {
                locationCode: this.searchParams.locationCode,
                locationName: this.searchParams.locationName,
                searchType: this.searchParams.searchType,
                roomsString: this.searchParams.roomsString,
                checkIn: this.searchParams.fromDate,
                checkOut: this.searchParams.toDate,
                searchId: allSearchBestRates.searchId,
                propertyId: itemHotel.propertyId,
                supplier: itemHotel.supplier,
                paxInfos: this.searchParams.rooms
              };
              this.markers.push({
                contents: itemHotel,
                lat: itemHotel.latitude,
                lng: itemHotel.longitude,
                urlDetail: btoa(encodeURIComponent(JSON.stringify(params))),
                label: {
                  color: '#00ad40',
                  fontFamily: this.fontFamilyMap,
                  fontSize: '14px',
                  text:
                    (
                      this.adultTicketPrice +
                      this.countPriceOnePerson(itemHotel.totalPrice)
                    ).toLocaleString('en-us', {
                      minimumFractionDigits: 0
                    }) + ' VND',
                  fontWeight: '400'
                },
                icon: {
                  url: 'assets/images/ven.png'
                },
                draggable: true
              });
            });
            if (this.markers.length > 0) {
              this.lat = this.markers[0].lat;
              this.lng = this.markers[0].lng;
            }
            if (this.openMarker) {
              this.markers[this.openMarker.index].label = {
                color: '#fff',
                fontFamily: 'custom-label-hover',
                fontSize: '14px',
                text:
                  this.openMarker.label.toLocaleString('en-us', {
                    minimumFractionDigits: 0
                  }) + ' VND',

                fontWeight: '400'
              };
            }
          } else {
            this.isLoading = false;
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.system-error-message',
                heading: 'user.system-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
        }
      })
    );
  }

  scrollToAmenities(el: HTMLElement) {
    if (isPlatformBrowser(this.platformId)) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  viewDetailItem(param) {
    const value = param.contents;
    const params = {
      locationCode: this.searchParams.location.name,
      locationName: this.searchParams.location.name,
      searchType: this.searchParams.location.searchType,
      roomsString: this.searchParams.roomsString,
      checkIn: this.searchParams.fromDate,
      checkOut: this.searchParams.toDate,
      searchId: this.searchId,
      propertyId: value.propertyId,
      supplier: value.supplier,
      paxInfos: this.searchParams.rooms
    };
    this.searchParams = {
      ...this.searchParams,
      searchId: this.searchId,
      propertyId: value.propertyId,
      location: {
        ...this.searchParams.location,
        supplier: value.supplier
      }
    };
    this._comboSelectedService.selectHotel(value);
    localStorage.setItem('hotelBooking', JSON.stringify(value));
    this.router.navigate(['/combo/hotel/detail'], {
      queryParams: {
        params: btoa(encodeURIComponent(JSON.stringify(this.searchParams)))
      }
    });
  }

  private _handleQueryFromParams(param) {
    this.searchParams = param;
    let roomsArrs = JSON.parse(param.rooms);
    let roomsString = [];
    if (roomsArrs.length > 0) {
      roomsArrs.forEach(roomsArr => {
        let childrendString = '';
        if (roomsArr.childrenAges) {
          roomsArr.childrenAges.forEach(childrend => {
            childrendString = childrend
              ? childrend + ',' + childrend
              : childrend;
          });
        }
        let room = childrendString
          ? roomsArr.adultQuantity + '-' + childrendString
          : roomsArr.adultQuantity;
        roomsString.push(room);
      });
    } else {
      roomsString.push(roomsArrs);
    }
    this.searchParams.roomsString = roomsString;
    this.searchParams.roomsArrs = roomsArrs;
    this.currentFilter = {
      language: this.currentLang,
      currency: 'VND',
      filterHotelName: param.filterHotelName,
      filterHotelCategories: param.filterHotelCategories,
      filterPrice: param.filterPrice,
      filterStarRating: param.filterStarRating,
      filterGuestRating: param.filterGuestRating,
      filterPropertyAmenities: param.filterPropertyAmenities,
      filterRoomAmenities: param.filterRoomAmenities,
      filterRoomViews: param.filterRoomViews,
      filterThemes: param.filterThemes,
      filterMealPlans: param.filterMealPlans,
      sort: param.sort,
      pageNumber: param.pageNumber ? param.pageNumber : 0,
      pageSize: param.pageSize ? param.pageSize : 15
    };
    if (param.openMarker) {
      this.openMarker = param.openMarker;
    }
  }
  countPriceOnePerson(totalPrice: number) {
    const numberPerson = this.searchParams.roomsArrs.reduce((arr, cur) => {
      return arr + cur.adultQuantity + cur.infantCount + cur.childQuantity;
    }, 0);
    return Math.ceil(totalPrice / numberPerson);
  }
  clickedMarker(label: string, index: number, infoWindow) {
    if (this.lastOpen) {
      this.markers[this.lastOpen.index].label = {
        color: '#00ad40',
        fontFamily: 'custom-label-price',
        fontSize: '14px',
        text: this.lastOpen.label,
        fontWeight: '400'
      };
    }
    this.lastOpen = {
      index: index,
      label: label
    };
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
    this.markers[index].label = {
      color: '#fff',
      fontFamily: 'custom-label-hover',
      fontSize: '14px',
      text: label,
      fontWeight: '400'
    };
  }

  hotelItemHover(event) {
    if (this.lastOpen) {
      this.markers[this.lastOpen.index].label = {
        color: '#00ad40',
        fontFamily: 'custom-label-price',
        fontSize: '14px',
        text: this.lastOpen.label,
        fontWeight: '400'
      };
      if (this.previousIW) {
        this.previousIW.close();
      }
    }
    this.markers[event.index].label = {
      color: event.type === 'hover' ? '#fff' : '#00ad40',
      fontFamily:
        event.type === 'hover' ? 'custom-label-hover' : 'custom-label-price',
      fontSize: '14px',
      text:
        (
          this.adultTicketPrice +
          this.countPriceOnePerson(event.itemHotel.totalPrice)
        ).toLocaleString('en-us', {
          minimumFractionDigits: 0
        }) + ' VND',
      fontWeight: '400'
    };
    setTimeout(() => {
      this.openMarker = null;
    }, 500);
  }

  mapClicked($event: MouseEvent) {
    // if (this.previousIW) {
    //   this.previousIW.close();
    // }
  }
  closeMarker(label: string, index: number) {
    this.previousIW.close();
    this.markers[index].label = {
      color: '#00ad40',
      fontFamily: 'custom-label-price',
      fontSize: '14px',
      text: label,
      fontWeight: '400'
    };
  }
  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  changeTabSelect() {
    this._location.back();
  }

  isHideListItemAction() {
    this.isHideListItem = !this.isHideListItem;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.filterAvailableFacade.resetFilter();
    this.searchBestRatesFacade.searchResetAll();
  }

  mathFloor(star: number) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}
interface marker {
  lat: number;
  lng: number;
  label?: string | mapTypes.MarkerLabel;
  icon?: {
    labelOrigin?: {
      x: number;
      y: number;
    };
    url?: string;
  };
  animation?: Animation;
  contents?: any;
  urlDetail?: string;
  draggable: boolean;
}
