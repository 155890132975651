import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingResultComponent } from './booking-result.component';
import { BookingResultModuleRouting } from './booking-result.module.routing';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  faCheck,
  faClock,
  faStar,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import {MatBottomSheetModule, MatButtonModule, MatTableModule} from '@angular/material';
import { BookingDetailLoadingComponent } from './booking-detail-loading/booking-detail-loading.component';
import { StateBookingResultModule } from '../state/booking-result/state-booking-result.module';
import { TranslateModule } from '@ngx-translate/core';
import {
  BookingResultFlightComponent,
  GetPassengerNameRecord
} from './booking-result-flight/booking-result-flight.component';
import {
  BookingResultHotelComponent,
  NumberNightResult
} from './booking-result-hotel/booking-result-hotel.component';
import { InsurancePolicyByBookingModule } from '../state/insurance-policy-by-booking/insurance-policy-by-booking.module';
import { PageErrorApiModule } from '@gtd/components/page-error-api';
import {B2cApiClientModule, BasePartnerResourceService} from '@gtd/b2c-client';
import { SnackbarModule } from '@gtd/components/snackbar';
import { DialogMessageModule } from '@gtd/components/dialog-message';
import { BookingResultBottomSheetComponent } from './booking-result-bottom-sheet/booking-result-bottom-sheet.component';
import {
  BookingResultComboComponent
} from './booking-result-combo/booking-result-combo.component';
import { environment } from '@env/environment';
import { BASE_PATH } from '@gtd/api-services/utilitysrv';
import { ApprovalInvoiceResourceService } from '@gtd/api-services/utilitysrv';
import { BookingResultTourComponent } from './booking-result-tour/booking-result-tour.component';
import { TourStoreModule } from '@gtd/extra/tour/data-access';
import { TourBookingDetailModule } from '@gtd/extra/tour/ui/tour-booking-detail';
import {TcbFxTransactionModule} from "@gtd/components/tcb-fx-transaction";
import {MetaServiceApiModule} from "@gtd/api-services/metasrv";
import {
  ServiceInventoryModule
} from '../../../checkout/src/lib/checkout-states/service-inventory/service-inventory.module';
import { BookingSummaryPanelModule } from '@gtd/components/booking-summary-panel';
import { BoxFlightBookingInfoModule } from '@gtd/components/box-flight-booking-info';
import { TourDetailSummaryModule } from '@gtd/extra/tour/ui/tour-detail-summary';
import { HotelDetailBoxModule } from '@gtd/components/hotel-detail-box';
import { PipesGetPnrModule } from '@gtd/pipes/get-pnr';

library.add(faCheckCircle, faCheck, faTimes, faTimesCircle, faClock, faStar);

@NgModule({
  imports: [
    CommonModule,
    BookingResultModuleRouting,
    FontAwesomeModule,
    FlexLayoutModule,
    MatButtonModule,
    StateBookingResultModule,
    TranslateModule,
    InsurancePolicyByBookingModule,
    PageErrorApiModule,
    SnackbarModule,
    DialogMessageModule,
    MatBottomSheetModule,
    TourStoreModule,
    TourBookingDetailModule,
    MatTableModule,
    TcbFxTransactionModule,
    B2cApiClientModule,
    MetaServiceApiModule,
    ServiceInventoryModule,
    BookingSummaryPanelModule,
    BoxFlightBookingInfoModule,
    TourDetailSummaryModule,
    HotelDetailBoxModule,
    PipesGetPnrModule
  ],
  declarations: [
    BookingResultComponent,
    BookingDetailLoadingComponent,
    BookingResultFlightComponent,
    BookingResultHotelComponent,
    BookingResultComboComponent,
    BookingResultTourComponent,
    NumberNightResult,
    GetPassengerNameRecord,
    BookingResultBottomSheetComponent
  ],
  entryComponents: [BookingResultBottomSheetComponent],
  providers: [
    BasePartnerResourceService,
    ApprovalInvoiceResourceService,
    { provide: BASE_PATH, useValue: environment.api.utilitysrv }
  ]
})
export class BookingResultModule {}
