import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxPassengerBaggageComponent } from './box-passenger-baggage.component';
import { AirlinesModule } from '@gtd/components/airlines';
import { FlexModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    AirlinesModule,
    FlexModule,
    FontAwesomeModule,
    TranslateModule
  ],
  declarations: [BoxPassengerBaggageComponent],
  exports: [BoxPassengerBaggageComponent]
})
export class BoxPassengerBaggageModule {}
