import { Component, Input, OnInit } from '@angular/core';
import { BookingServiceRequestInfo, BookingTravelerInfo, GroupPricedItinerary } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-box-passenger-baggage',
  templateUrl: './box-passenger-baggage.component.html',
  styleUrls: ['./box-passenger-baggage.component.scss']
})
export class BoxPassengerBaggageComponent implements OnInit {
  @Input() groupPricedItineraries: Array<GroupPricedItinerary>;
  @Input() travelerInfos: Array<BookingTravelerInfo>;

  constructor() { }

  ngOnInit() {
  }
  generateIconGender(type: string) {
    let gender = 'icon-male';
    switch (type) {
      case 'MALE':
      case 'BOY':
        gender = 'icon-male'
        break;
      case 'GIRL':
      case 'INF':
      case 'FEMALE':
        gender = 'icon-female'
        break;
    }
    return gender
  }

  getBaggageTraveller(serviceRequests: Array<BookingServiceRequestInfo>, flightDirection: string) {
    if(serviceRequests && serviceRequests.length) {
      if(flightDirection === 'D') {
        let services = serviceRequests.filter(
          serviceRequest =>
            serviceRequest.serviceType === 'BAGGAGE'
            && serviceRequest.bookingDirection === 'DEPARTURE'
            && serviceRequest.ssrCode !== 'FreeBAGGAGE'
        )
        return services.length? services[0].ssrName : null;
      }
      if(flightDirection === 'R') {
        let services = serviceRequests.filter(
          serviceRequest =>
            serviceRequest.serviceType === 'BAGGAGE'
            && serviceRequest.bookingDirection === 'RETURN'
            && serviceRequest.ssrCode !== 'FreeBAGGAGE'
        )
        return services.length? services[0].ssrName : null;
      }
    }
    return null;
  }
}
