import { Pipe, PipeTransform } from '@angular/core';
import { BookingInfo, BookingTransactionInfo } from '@gtd/b2c-client';

@Pipe({
  name: 'getComboPassengerNameRecord'
})
export class GetComboPassengerNameRecord implements PipeTransform {
  transform(bookingInfo: BookingInfo, type: 'DEPARTURE' | 'RETURN'): string {
    const airBookingTransactionInfos: BookingTransactionInfo[] = bookingInfo.transactionInfos.filter(
      trans => trans.supplierType === 'AIR'
    );
    let bookingTransactionInfos = new Array<BookingTransactionInfo>();
    switch (type) {
      case 'DEPARTURE':
        bookingTransactionInfos = airBookingTransactionInfos.filter(
          trans => trans.originLocationCode === bookingInfo.fromLocationCode
        );
        break;
      case 'RETURN':
        bookingTransactionInfos = airBookingTransactionInfos.filter(
          trans => trans.originLocationCode === bookingInfo.toLocationCode
        );
        break;
    }
    if (bookingTransactionInfos && bookingTransactionInfos.length > 0) {
      return bookingTransactionInfos[0].passengerNameRecord;
    }
    return '';
  }
}
