import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComboSelectedService } from '../combo-selected.service';

@Component({
  selector: 'gtd-combo-flight-booking',
  templateUrl: './combo-flight-booking.component.html',
  styleUrls: ['./combo-flight-booking.component.scss']
})
export class ComboFlightBookingComponent implements OnInit {
  @Input() booking: any;
  @Input() searchParams: any;
  @Input() titleHeading: string;
  @Input() isRow = false;
  @Input() isIssetHotel = true;
  @Input() selectedByUser = false;
  @Input() flightNotFound = false;

  constructor(
    private _comboSelectedService: ComboSelectedService,
    private router: Router
  ) {}

  ngOnInit() {}

  getDestination(item: any, type: string, idx: number): any {
    if (item.itemFlight.flightType === 'INTERNATIONAL') {
      let originDestinationOptions = item.pricedItinerary.originDestinationOptions.filter(
        (originDestinationOption: any) =>
          originDestinationOption.flightDirection === (idx === 0 ? 'D' : 'R')
      );
      if (!originDestinationOptions.length) return null;
      return {
        city:
          type === 'destination'
            ? originDestinationOptions[0].destinationCity
            : originDestinationOptions[0].originCity,
        city2:
          type === 'destination'
            ? originDestinationOptions[0].destinationCity
            : originDestinationOptions[0].originCity,
        code:
          type === 'destination'
            ? originDestinationOptions[0].destinationLocationCode
            : originDestinationOptions[0].originLocationCode
      };
    } else {
      const flight = item.itemFlight;
      return {
        city:
          type === 'destination' ? flight.destinationCity : flight.originCity,
        city2:
          type === 'destination' ? flight.destinationCity : flight.originCity,
        code:
          type === 'destination'
            ? flight.destinationLocationCode
            : flight.originLocationCode
      };
    }
  }

  resetFlight(type: 'from' | 'to') {
    const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));
    this._comboSelectedService.removeFlight(
      type,
      flightBooking[type === 'from' ? 0 : 1].pricedItinerary
        .airItineraryPricingInfo.itinTotalFare.totalFare.amount
    );
    let dataComboSearch = localStorage.getItem('dataComboSearch');
    let params = JSON.stringify({
      ...JSON.parse(dataComboSearch),
      flightType: flightBooking[0].itemFlight.flightType,
      typeSelect: type
    });
    this.router.navigate(['/combo/flight'], {
      queryParams: {
        params: btoa(encodeURIComponent(params))
      }
    });
  }
  getGroupPricedItinerary(item: any, idx: number) {
    if (item.itemFlight.flightType === 'INTERNATIONAL') {
      let originDestinationOptions = item.pricedItinerary.originDestinationOptions.filter(
        (originDestinationOption: any) =>
          originDestinationOption.flightDirection === (idx === 0 ? 'D' : 'R')
      );
      return originDestinationOptions.length
        ? originDestinationOptions[0]
        : null;
    } else {
      return item.itemFlight;
    }
  }

  getDateTimeFlight(itemFlight: any, type: 'D' | 'R') {
    if (
      itemFlight.pricedItineraries &&
      itemFlight.pricedItineraries.length &&
      itemFlight.pricedItineraries[0].originDestinationOptions &&
      itemFlight.pricedItineraries[0].originDestinationOptions.length
    ) {
      let itemFlightType = itemFlight.pricedItineraries[0].originDestinationOptions.filter(
        destination => destination.flightDirection === type
      );
      return itemFlightType.length ? itemFlightType[0].originDateTime : null;
    }
  }
}
