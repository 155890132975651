import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SimApiResourceService, SimResult } from '@gtd/api-services/service-inventory';

@Component({
  selector: 'gtd-modal-sim-item-detail',
  templateUrl: './modal-sim-item-detail.component.html',
  styleUrls: ['./modal-sim-item-detail.component.scss']
})
export class ModalSimItemDetailComponent implements OnInit {
  simDetail: SimResult;
  isLoading: boolean;

  constructor(
    private simApiResourceService: SimApiResourceService,
    public dialogRef: MatDialogRef<ModalSimItemDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if(this.data.simDetail) {
      this.isLoading = true;
      setTimeout(() => {
        this.simApiResourceService.getDetailSimByCode(this.data.simDetail.code).subscribe(detail => {
          if(detail && detail.success) {
            this.simDetail = detail.result;
            this.isLoading = false
          }
        }, error => this.isLoading = false)
      }, 100)
    }
  }

  closeModal() {
    this.dialogRef.close()
  }
}
