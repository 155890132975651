import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelDetailBoxComponent, NumberNight } from './hotel-detail-box/hotel-detail-box.component';
import { FlexModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCardModule, MatMenuModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FlexModule,
    FontAwesomeModule,
    MatCardModule,
    MatMenuModule,
    TranslateModule
  ],
  declarations: [HotelDetailBoxComponent, NumberNight],
  exports: [HotelDetailBoxComponent]
})
export class HotelDetailBoxModule {}
