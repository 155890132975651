import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { CancelPenalty, GroupBooking } from '@gtd/b2c-client';
import { SimBookingNumberResult } from '@gtd/api-services/service-inventory';

@Component({
  selector: 'gtd-combo-booking-summary-panel',
  templateUrl: './booking-summary-panel.component.html',
  styleUrls: ['./booking-summary-panel.component.scss']
})
export class ComboBookingSummaryPanelComponent implements OnInit {
  @Input() bookingDetail: GroupBooking;
  @Input() showInfo: boolean;
  @Input() isBookingDetail: boolean;
  @Input() isStatusSIM: boolean;
  @Input() simDetail: SimBookingNumberResult;
  isMemberCardType: boolean = true;
  panelOpenState: boolean;
  hotelDetail: any;

  constructor() {}

  ngOnInit() {
    this.hotelDetail = this.bookingDetail.bookingInfo.transactionInfos.find(
      transaction => transaction.supplierType === 'HOTEL'
    );
  }


  getDestination(item: any, type: 'destination' | 'origin'): any {
    if (!item || !item.itemFlight) {
      return null;
    }
    const flight = item.itemFlight;
    return {
      city: type === 'destination' ? flight.destinationCity : flight.originCity,
      city2: type === 'destination' ? flight.destinationCity : flight.originCity,
      code: type === 'destination' ? flight.destinationLocationCode : flight.originLocationCode,
    };
  }

  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
  protected readonly counter = Array;

  startDate: string;
  endDate: string;
  getCancelPelnaltiesDate(type: 'start' | 'end', cancelPenalty: CancelPenalty) {
    this.startDate = cancelPenalty.startDate
      .split(' ')[0]
      .replace('.999999999', '');
    this.endDate = cancelPenalty.endDate
      .split(' ')[0]
      .replace('.999999999', '');
    if (type == 'start') {
      return this.startDate;
    }
    return this.endDate;
  }

  getPnr() {
    if(this.bookingDetail.bookingInfo.transactionInfos.length) {
      let transactionInfo = this.bookingDetail.bookingInfo.transactionInfos.filter(
        transactionInfo => transactionInfo.supplierType === 'HOTEL'
      )
      if(transactionInfo && transactionInfo.length) {
        return transactionInfo[0].passengerNameRecord
      }
    }
  }
}

