import { Component, Input, OnInit } from '@angular/core';
import { ModalSimItemDetailComponent } from '@gtd/components/modal-sim';
import { MatDialog } from '@angular/material';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'gtd-gift-service',
  templateUrl: './gift-service.component.html',
  styleUrls: ['./gift-service.component.scss']
})
export class GiftServiceComponent implements OnInit {
  @Input() label: string;
  @Input() selectedItem: any;
  @Input() isGiftService: boolean;

  constructor(
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit() {
  }

  detailSim() {
    if(this.selectedItem) {
      this.dialog.open(ModalSimItemDetailComponent, {
        data: {
          simDetail: ({
            ...this.selectedItem,
            code: this.selectedItem.refId
          })
        },
        width: this.deviceService.isMobile()? '100%': '745px',
        maxWidth: this.deviceService.isMobile()? '100%': '80vw',
        position: {
          top: this.deviceService.isMobile()? '0' :'100px'
        },
        panelClass: this.deviceService.isMobile()? [
          'modal-sim-detail',
          'animate__animated',
          'animate__slideInUp',
          'animate__faster',
          'full-height',
          'full-screen'
        ]: 'modal-sim-detail',
        autoFocus: false
      });
    }
  }
}
