import { Component, Input, OnInit } from '@angular/core';
import { PropertyAllRate } from '@gtd/b2c-client';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'gtd-tabbar-detail',
  templateUrl: './tabbar-detail.component.html',
  styleUrls: ['./tabbar-detail.component.scss'],
})
export class TabbarDetailComponent implements OnInit {
  @Input() propertyAllRate: PropertyAllRate;
  searchParams: any;

  appName = localStorage.getItem('appName');

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this.searchParams = JSON.parse(decodeURIComponent(atob(param.params)));
    });
  }

  ngOnInit() {

  }

  backSearchResult() {
    if (this.searchParams) {
      //   let data = {
      //     locationCode: this.searchParams.locationCode,
      //     searchType: this.searchParams.searchType,
      //     supplier: this.searchParams.supplier,
      //     locationName: this.searchParams.locationName,
      //     fromDate: this.searchParams.checkIn,
      //     toDate: this.searchParams.checkOut,
      //     rooms: this.searchParams.paxInfos
      //   };
      this.router.navigate(['/combo/hotel'], {
        queryParams: {
          params: btoa(encodeURIComponent(JSON.stringify(this.searchParams)))
        }
      });
    }
  }
}
