/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { SimBookingNumberDetailApiRs, SimDataRequest, SimDetailApiRs } from '../model/simResult';
import { CustomHttpUrlEncodingCodec } from '../encoder';


@Injectable()
export class SimApiResourceService {

    protected basePath = 'https://10.7.71.20:7883';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



  /**
   * createSimOrder
   *
   * @param body SimDataRequest
   * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createSimOrder(body: SimDataRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createSimOrder(body: SimDataRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createSimOrder(body: SimDataRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createSimOrder(body: SimDataRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createSimOrder.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/inventory/sim/order/create`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * createSimOrder
   *
   * @param bookingNumber string
   * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public issueSimOrder(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public issueSimOrder(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public issueSimOrder(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public issueSimOrder(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (bookingNumber === null || bookingNumber === undefined) {
      throw new Error('Required parameter bookingNumber was null or undefined when calling createSimOrder.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/inventory/sim/order/issue?bookingNumber=${bookingNumber}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
