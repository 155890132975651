import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetComboPassengerNameRecord } from './get-pnr.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [GetComboPassengerNameRecord],
  exports: [GetComboPassengerNameRecord]
})
export class PipesGetPnrModule {}
