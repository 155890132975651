import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  AirTicketsResourceService,
  FareRules,
  FlightSegment,
  GroupPricedItinerary
} from '@gtd/b2c-client';
import { GaTrackingService } from '@gtd/ga-tracking';

@Component({
  selector: 'gtd-booking-int-journey-detail',
  templateUrl: './booking-int-journey-detail.component.html',
  styleUrls: ['./booking-int-journey-detail.component.scss']
})
export class BookingIntJourneyDetailComponent implements OnInit {
  @Input() groupPricedItinerary: GroupPricedItinerary;
  @Input() bookingNumber: string;
  flightSegments?: Array<FlightSegment>;

  isDetailJourney2: boolean;
  panelOpenState: boolean;

  submitLoadMore: boolean = false;
  allFarerules: FareRules[];

  constructor(
    private airTicketsResourceService: AirTicketsResourceService,
    private _gaTrackingService: GaTrackingService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      if (this.groupPricedItinerary) {
        this.flightSegments = this.groupPricedItinerary.pricedItineraries[0].originDestinationOptions[1].flightSegments;
      }
    });
  }

  getFareRules() {
    this.submitLoadMore = true;
    this._gaTrackingService.track('F_FlightDetail_TapFlightCondition');
    if (this.bookingNumber) {
      this.airTicketsResourceService
        .retrieveAirFareRulesUsingGET(this.bookingNumber)
        .subscribe(allFarerules => {
          if (allFarerules) {
            this.submitLoadMore = false;
            this.allFarerules = allFarerules.bookedFareRules[0].fareRules;
          }
        });
    }
  }
}
