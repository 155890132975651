import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GiftServiceComponent } from './gift-service/gift-service.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalSimModule } from '@gtd/components/modal-sim';
import { MatDialogModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ModalSimModule,
    MatDialogModule
  ],
  declarations: [
    GiftServiceComponent
  ],
  exports: [
    GiftServiceComponent
  ]
})
export class ComponentsGiftServiceModule {}
