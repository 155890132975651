import { Component, Input, OnInit } from '@angular/core';
import { GroupBooking } from '@gtd/b2c-client';
import { SimBookingNumberResult, SimResult } from '@gtd/api-services/service-inventory';
import { ModalSimItemDetailComponent } from '@gtd/components/modal-sim';
import { MatDialog } from '@angular/material';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'gtd-panel-booking-detail',
  templateUrl: './panel-booking-detail.component.html',
  styleUrls: ['./panel-booking-detail.component.scss']
})
export class PanelBookingDetailComponent implements OnInit {
  @Input() bookingDetail: GroupBooking;
  @Input() simDetail: SimBookingNumberResult;
  @Input() isStatusSIM: boolean;
  isShowInfo: boolean = true;
  panelOpenShowInfoState: boolean[] = [];
  isShowPassenger = -1;

  constructor(
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
  ) { }

  ngOnInit() {
    this.panelOpenShowInfoState = this.bookingDetail.bookingInfo.travelerInfos.map(() => false);
  }

  generateIconGender(type: string) {
    let gender = 'icon-male';
    switch (type) {
      case 'MALE':
      case 'BOY':
        gender = 'icon-male'
        break;
      case 'GIRL':
      case 'INF':
      case 'FEMALE':
        gender = 'icon-female'
        break;
    }
    return gender
  }

  detailItemSim(simDetail: SimResult) {
    this.dialog.open(ModalSimItemDetailComponent, {
      width: this.deviceService.isMobile()? '100%': '745px',
      maxWidth: this.deviceService.isMobile()? '100%': '80vw',
      position: {
        top: this.deviceService.isMobile()? '0' :'100px'
      },
      data: {
        simDetail: simDetail
      },
      panelClass: this.deviceService.isMobile()? [
        'modal-sim-detail',
        'animate__animated',
        'animate__slideInUp',
        'animate__faster',
        'full-height',
        'full-screen'
      ]: 'modal-sim-detail',
      autoFocus: false
    });
  }

  convertItemToModelOrder(selectedGift: any) {
    return ({
      ...selectedGift,
      code: selectedGift.refId
    })
  }
}
