import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {Router} from '@angular/router';
import {PropertyAvailable} from '@gtd/b2c-client';
import {fadeIn} from '@gtd/helpers';
import {HotelFunctionService} from '@gtd/hotels/data-access/services';
import {HotelSearchBestRatesFacade, HotelSearchParamsFacade} from '@gtd/hotels/data-access/store';
import {ExchangeOptionService, PartnerService} from '@gtd/lotus/shared/data-access/services';
import {ArrayUtils} from '@gtd/utilities';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'gtd-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss'],
  animations: [fadeIn]
})
export class SearchItemComponent implements OnInit {
  @Input() item: PropertyAvailable;
  @Input() shortLinkId?: string;
  @Input() mapView: boolean;

  search: any;
  searchId: string;
  ratings: Array<any>;
  dayGap: number;
  partner$: Observable<string>;
  exchangeOption;
  utm_source = localStorage.getItem('utm_source')

  constructor(
    private _hotelSearchParamsFacade: HotelSearchParamsFacade,
    private _hotelSearchBestRatesFacade: HotelSearchBestRatesFacade,
    private _hotelFunctionService: HotelFunctionService,
    private _partnerService: PartnerService,
    private _router: Router,
    private _exchangeOptionService: ExchangeOptionService
  ) {
    _exchangeOptionService.option$.subscribe(option => {
      this.exchangeOption = option;
    });
    _hotelSearchParamsFacade.allHotelSearchParams$
      .pipe(tap(params => (this.search = params)))
      .subscribe(params => {
        this.dayGap = _hotelFunctionService.getDayGap(
          params.fromDate,
          params.toDate
        );
      });
    _hotelSearchBestRatesFacade.searchId$
      .pipe(tap(id => (this.searchId = id)))
      .subscribe();
    this.partner$ = this._partnerService.partner$;
  }

  ngOnInit() {

  }

  generateStarHotel(stars: string): string[] {
    const fullStars = Math.floor(Number(stars));
    const halfStar = Number(stars) % 1 >= 0.5 ? 1 : 0;

    const starArray = Array(fullStars).fill('full');
    if (halfStar) {
      starArray.push('half');
    }

    return starArray;
  }

  get params() {
    return this._hotelFunctionService.generateUrl(
      this.shortLinkId,
      this.item.masterPropertyId,
      this.item.propertyId,
      this.item.supplier
    );
  }


  stop(event) {
    event.stopPropagation();

    event.preventDefault();
  }
  navigateDetail() {
    if (this.exchangeOption == 'CASH&MILES') {
      return;
    }
    const host: string =  location.origin;
    const url: string = host + String(this._router.createUrlTree(['hotels/detail'], { queryParams: {
        sid: this.shortLinkId,
        mid: this.item.masterPropertyId,
        pid: this.item.propertyId,
        p: btoa(this.item.supplier)
      } }));
    window.open(url, '_blank');

  }

  getPricePerNight(type: 'after' | 'before', utm_source?: string) {
    let numberOfRooms = this.search.roomsArrs.length;
    let numberOfNights = this.dayGap;
    if(utm_source !== 'DHA') {
      const after =
        Math.ceil(this.item.basePrice / (numberOfRooms * numberOfNights * 1000)) *
        1000;
      const before =
        Math.ceil(
          this.item.basePriceBeforePromo / (numberOfRooms * numberOfNights * 1000)
        ) * 1000;
      return type == 'after' ? after : before;
    } else {
      const after = this.item.basePrice / (numberOfRooms * numberOfNights);
      const before =this.item.basePriceBeforePromo / (numberOfRooms * numberOfNights)
      return type == 'after' ? after : before;
    }
  }

  getNumberOfPassengers(type: 'adult' | 'child') {
    return this.search.roomsArrs.reduce((arr, cur) => {
      return arr + cur[type == 'adult' ? 'adultQuantity' : 'childQuantity'];
    }, 0);
  }
}

@Pipe({ name: 'percentPrice' })
export class PercentPrice {
  transform(price: number, basePriceBeforePromo: number) {
    let percent = 100 - (price * 100) / basePriceBeforePromo;
    return Math.round(percent);
  }
}

@Pipe({
  name: 'roundReviewScore'
})
export class RoundReviewScorePipe implements PipeTransform {
  transform(value: string): string {
    const numericValue = Number(value);
    const roundedValue = Math.round(numericValue * 10) / 10;
    if (Number.isInteger(roundedValue)) {
      return roundedValue.toFixed(0);  // Return as integer
    }
    return roundedValue.toFixed(1);
  }

}
