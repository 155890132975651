import {
  AvailablePlansAction,
  AvailablePlansActionTypes, DestroyAvailablePlans
} from './available-plans.actions';
import {ListResponseOfSSRItemDTO, OtaResultOfListOfSSRItemDTO} from "@gtd/api-services/service-inventory";

export const AVAILABLEPLANS_FEATURE_KEY = 'availablePlans';

/**
 * Interface for the 'AvailablePlans' data used in
 *  - AvailablePlansState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface AvailablePlansState {
  list: OtaResultOfListOfSSRItemDTO; // list of AvailablePlans; analogous to a sql normalized table
  selectedId?: string | number; // which AvailablePlans record has been selected
  loaded: boolean; // has the AvailablePlans list been loaded
  error?: any; // last none error (if any)
}

export interface AvailablePlansPartialState {
  readonly [AVAILABLEPLANS_FEATURE_KEY]: AvailablePlansState;
}

export const initialState: AvailablePlansState = {
  list: null,
  loaded: false
};

export function availablePlansReducer(
  state: AvailablePlansState = initialState,
  action: AvailablePlansAction
): AvailablePlansState {
  switch (action.type) {
    case AvailablePlansActionTypes.AvailablePlansLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case AvailablePlansActionTypes.AvailablePlansLoadError: {
      initialState.loaded = true;
      state = initialState;
    }
    case AvailablePlansActionTypes.DestroyAvailablePlans: {
      initialState.loaded = false;
      state = initialState;
    }
  }
  return state;
}
