import { Component, Input, OnInit } from '@angular/core';
import { TourActivityProduct, TourContentReference } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-tour-detail-summary',
  templateUrl: './tour-detail-summary.component.html',
  styleUrls: ['./tour-detail-summary.component.scss']
})
export class TourDetailSummaryComponent implements OnInit {
  @Input() tourDetail: TourActivityProduct;
  @Input() tourDepartureDate: Date;
  @Input() tourReturnDate: Date;
  @Input() tourPaxInfos: any;

  allAmenities: Array<TourContentReference>;

  constructor() {}

  ngOnInit() {
    if (this.tourDetail) {
      this.allAmenities = Object.keys(this.tourDetail.amenities).map(
        key => this.tourDetail.amenities[key]
      );
    }
  }
}
