import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatTabChangeEvent} from '@angular/material';
import {AirTicketsResourceService, GroupPricedItinerary} from '@gtd/b2c-client';
import {environment} from '@env/environment';
import {fadeIn} from '@gtd/helpers';

@Component({
  selector: 'gtd-flight-detail-dialog',
  templateUrl: './flight-detail-dialog.component.html',
  styleUrls: ['./flight-detail-dialog.component.scss'],
  animations: [fadeIn]
})
export class FlightDetailDialogComponent implements OnInit, OnDestroy {
  priceDetailToggle = false;
  allFarerules: any;
  isLoading: boolean;

  constructor(
    private _dialogRef: MatDialogRef<FlightDetailDialogComponent>,
    private airTicketsResourceService: AirTicketsResourceService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      flightItem: GroupPricedItinerary;
      totalTax: number;
      bookingNumber: string;
      itemJourney: any
    }
  ) {}

  ngOnInit() {

  }
  getFareRules() {
    if (this.data.bookingNumber && !this.allFarerules) {
      this.isLoading = true;
      this.airTicketsResourceService
        .retrieveAirFareRulesUsingGET(this.data.bookingNumber)
        .subscribe(allFarerules => {
          this.isLoading = false;
          if (allFarerules) {
            let fareRules = allFarerules.bookedFareRules.filter(farerules => farerules.groupId === this.data.flightItem.groupId);
            if(fareRules && fareRules.length) this.allFarerules = fareRules[0].fareRules
          }
        });
    }
  }
  displayAirlineLogo(airlineCode: string) {
    if (
      airlineCode === 'VN' ||
      airlineCode === 'VJ' ||
      airlineCode === 'QH' ||
      airlineCode === 'BL'
    )
      return environment.cdn.logoAirline + airlineCode + '.svg';
    else return environment.cdn.logoAirline + airlineCode + '.gif';
  }

  closeDialog() {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
  }

  tabChanged($event: MatTabChangeEvent) {
    if($event.index === 1) this.getFareRules();
  }
}
