import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import {
  GButtonModule,
  GMenuModule,
  GTypographyModule
} from '@gotadi/design-system';
import { TourLoadingBoxModule } from '@gtd/extra/tour/ui/tour-loading-box';
import { PipesModule } from '@gtd/hotels/data-access/pipes';
import { PriceExchangeModule } from '@gtd/hotels/ui/price-exchange';
import { TranslateModule } from '@ngx-translate/core';
import {PercentPrice, RoundReviewScorePipe, SearchItemComponent} from './search-item.component';
import { SearchLoaderItemComponent } from './search-loader-item/search-loader-item.component';
import {CurrencyExchangeModule} from "@gtd/pipes/currency-exchange";
import {MatMenuModule} from '@angular/material/menu';
import {SanitizeModule} from "@gtd/pipes/sanitize";


@NgModule({
    imports: [
      CommonModule,
      GTypographyModule,
      GButtonModule,
      MatIconModule,
      GMenuModule,
      PipesModule,
      TranslateModule,
      RouterModule,
      PriceExchangeModule,
      TourLoadingBoxModule,
      CurrencyExchangeModule,
      MatMenuModule,
      SanitizeModule
    ],
  declarations: [SearchItemComponent, SearchLoaderItemComponent, PercentPrice, RoundReviewScorePipe],
  exports: [SearchItemComponent, SearchLoaderItemComponent]
})
export class SearchItemModule {}
