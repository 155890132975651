/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Amenity } from './amenity';
import { Attribute } from './attribute';
import { Checkin } from './checkin';
import { Checkout } from './checkout';
import { HotelImage } from './hotelImage';
import { PropertyCategory } from './propertyCategory';
import { PropertyRoom } from './propertyRoom';
import { Rating } from './rating';
import { PromoTag } from './propertyAvailable';

export interface PropertyAllRate {
  address?: Address;
  airportCode?: string;
  amenities?: Array<Amenity>;
  attributes?: Array<Attribute>;
  checkin?: Checkin;
  checkout?: Checkout;
  currency?: PropertyAllRate.CurrencyEnum;
  descriptions?: Array<Attribute>;
  fees?: Array<Attribute>;
  images?: Array<HotelImage>;
  inclusions?: Array<Attribute>;
  language?: PropertyAllRate.LanguageEnum;
  latitude?: number;
  longitude?: number;
  masterPropertyId?: number;
  policies?: Array<Attribute>;
  propertyCategory?: PropertyCategory;
  propertyId?: string;
  propertyName?: string;
  rank?: number;
  rateOption?: string;
  rating?: Rating;
  gifts?: PromoTag[];
  promoTags?: PromoTag[];
  rooms?: Array<PropertyRoom>;
  spokenLanguage?: Array<Attribute>;
  statistics?: Array<Attribute>;
  supplier?: PropertyAllRate.SupplierEnum;
  tags?: Array<string>;
  themes?: Array<Attribute>;
  stars: any;
  rewardExchangeRate: number;
}
export namespace PropertyAllRate {
  export type CurrencyEnum = 'VND' | 'USD';
  export const CurrencyEnum = {
    VND: 'VND' as CurrencyEnum,
    USD: 'USD' as CurrencyEnum
  };
  export type LanguageEnum = 'vi' | 'en';
  export const LanguageEnum = {
    Vi: 'vi' as LanguageEnum,
    En: 'en' as LanguageEnum
  };
  export type SupplierEnum =
    | 'GOTADI'
    | 'EXPEDIA'
    | 'AXISROOM'
    | 'BEDLINKER'
    | 'AGODA'
    | 'VINPEARL';
  export const SupplierEnum = {
    GOTADI: 'GOTADI' as SupplierEnum,
    EXPEDIA: 'EXPEDIA' as SupplierEnum,
    AXISROOM: 'AXISROOM' as SupplierEnum,
    BEDLINKER: 'BEDLINKER' as SupplierEnum,
    VINPEARL: 'VINPEARL' as SupplierEnum,
    AGODA: 'AGODA' as SupplierEnum
  };
}
