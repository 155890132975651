import { Component, Directive, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import { FilterAvailableFacade } from '../../../../../../state/filter-available/filter-available.facade';

@Component({
  selector: 'gtd-filter-by-ratings',
  templateUrl: './filter-by-ratings.component.html',
  styleUrls: ['./filter-by-ratings.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterByRatingsComponent),
      multi: true
    }
  ]
})
export class FilterByRatingsComponent implements OnInit, ControlValueAccessor {
  @Input() ratings: any;
  ratingForm: FormGroup;
  counter = Array;
  optionSelected: Array<any> = [];

  constructor(private formBuilder: FormBuilder, private filterAvailableFacade: FilterAvailableFacade) {
    this.ratingForm = this.formBuilder.group({
      star:  this.formBuilder.array([])
    });
  }

  ngOnInit() {
    const stars = <FormArray>this.ratingForm.get('star') as FormArray;
    this.ratings.forEach(rating => {
      stars.push(new FormControl(false))
    })
  }

  checkboxStar(index: number) {
    this.optionSelected = [];
    setTimeout(() => {
      if (this.ratingForm) {
        const starArray = this.ratingForm.controls['star'].value;
        this.optionSelected = starArray
          .map((isChecked, idx) => (isChecked ? this.ratings[idx].value : null))
          .filter(value => value !== null);
        if (this.optionSelected.includes(2)) {
          if (!this.optionSelected.includes(1)) {
            this.optionSelected.push(1);
          }
        } else {
          this.optionSelected = this.optionSelected.filter(value => value !== 2 && value !== 1);
        }
        this.filterAvailableFacade.changeFilterStarRating(this.optionSelected);
      }
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    if(obj) {
      let start = this.ratings.findIndex(x => x.value === obj.from);
      let end = this.ratings.findIndex(x => x.value === obj.to);
      for(let i = start; i <= end; i++) {
        this.ratingForm.controls['star'].get([i]).setValue(true);
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  constructor(private ngControl: NgControl) {}
}
