import { Action } from '@ngrx/store';
import { HotelSearchReq } from './search-best-rates.facade';
import { OtaResultOfSearchBestRatesResult, SearchBestRatesResult } from '@gtd/b2c-client';

export enum SearchBestRatesActionTypes {
  ComboVJLoadSearchBestRates = '[ComboVJ_SearchBestRates] Load SearchBestRates',
  ComboVJSearchBestRatesLoaded = '[ComboVJ_SearchBestRates] SearchBestRates Loaded',
  ComboVJSearchBestRatesLoadError = '[ComboVJ_SearchBestRates] SearchBestRates Load Error',
  ComboVJSearchBestRatesReset = '[ComboVJ_SearchBestRates] SearchBestRates Reset'
}

export class ComboVJLoadSearchBestRates implements Action {
  readonly type = SearchBestRatesActionTypes.ComboVJLoadSearchBestRates;
  constructor(public payload: HotelSearchReq, public loadMore?: boolean) {}
}

export class ComboVJSearchBestRatesLoadError implements Action {
  readonly type = SearchBestRatesActionTypes.ComboVJSearchBestRatesLoadError;
  constructor(public payload: any) {}
}

export class ComboVJSearchBestRatesLoaded implements Action {
  readonly type = SearchBestRatesActionTypes.ComboVJSearchBestRatesLoaded;
  constructor(public payload: SearchBestRatesResult, public loadMore?: boolean) {}
}

export class ComboVJSearchBestRatesReset implements Action {
  readonly type = SearchBestRatesActionTypes.ComboVJSearchBestRatesReset;
}

export type SearchBestRatesAction =
  | ComboVJLoadSearchBestRates
  | ComboVJSearchBestRatesLoaded
  | ComboVJSearchBestRatesReset
  | ComboVJSearchBestRatesLoadError;

export const fromSearchBestRatesActions = {
  ComboVJLoadSearchBestRates,
  ComboVJSearchBestRatesLoaded,
  ComboVJSearchBestRatesReset,
  ComboVJSearchBestRatesLoadError
};
