import {Component, Input, OnInit} from '@angular/core';
import {GroupBooking} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-box-flight-booking-info',
  templateUrl: './box-flight-booking-info.component.html',
  styleUrls: ['./box-flight-booking-info.component.scss']
})
export class BoxFlightBookingInfoComponent implements OnInit {
  @Input() isHeading: boolean = true;
  @Input() isBookingDetail: boolean;
  @Input() bookingDetail: GroupBooking;

  constructor() { }

  ngOnInit() {
  }

}
