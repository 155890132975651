import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { SearchBestRatesPartialState } from './search-best-rates.reducer';
import {
  ComboVJLoadSearchBestRates,
  SearchBestRatesActionTypes,
  ComboVJSearchBestRatesLoadError
} from './search-best-rates.actions';
import { catchError, delay, map, retry, switchMap } from 'rxjs/operators';
import { HotelResourceV3Service } from '@gtd/b2c-client';

@Injectable()
export class ComboSearchBestRatesEffects {
  @Effect() loadComboVJSearchBestRates$ = this.dataPersistence.fetch(
    SearchBestRatesActionTypes.ComboVJLoadSearchBestRates,
    {
      run: (
        action: ComboVJLoadSearchBestRates,
        state: SearchBestRatesPartialState
      ) => {
        this.hotelV3ResourceService.defaultHeaders = this.hotelV3ResourceService.defaultHeaders.set(
          'sales-env',
          'HOTEL_PACKAGE'
        );
        return this.hotelV3ResourceService
          .searchBestRatesUsingGET(
            action.payload.searchCode,
            action.payload.searchType,
            action.payload.language,
            action.payload.currency,
            action.payload.checkIn,
            action.payload.checkOut,
            action.payload.paxInfos,
            action.payload.searchId,
            action.payload.supplier,
            action.payload.targetSupplier,
            action.payload.filterHotelName,
            action.payload.filterHotelCategories,
            action.payload.filterBreakfastIncluded,
            action.payload.filterFromPrice,
            action.payload.filterToPrice,
            action.payload.filterFromDistance,
            action.payload.filterToDistance,
            action.payload.filterStarRating,
            action.payload.filterFromGuestRating,
            action.payload.filterToGuestRating,
            action.payload.filterPropertyAmenities,
            action.payload.filterRoomAmenities,
            action.payload.filterRateAmenities,
            action.payload.filterRoomViews,
            action.payload.filterThemes,
            action.payload.filterMealPlans,
            action.payload.filterBedTypes,
            action.payload.filterGeoDistanceLat,
            action.payload.filterGeoDistanceLon,
            action.payload.filterGeoDistanceMeters,
            action.payload.sortField,
            action.payload.sortOrder,
            action.payload.pageNumber,
            action.payload.pageSize
          )
          .pipe(
            map(payload => {
              if(payload && payload.result) {
                if(!payload.result.propertyAvailable || !payload.result.propertyAvailable.length) {
                  retry(1)
                }
              }
              return ({
                type: SearchBestRatesActionTypes.ComboVJSearchBestRatesLoaded,
                payload: payload.result,
                loadMore: action.loadMore
              })
            }),
            retry(1)
          );
      },

      onError: (action: ComboVJLoadSearchBestRates, error) => {
        console.error('Error', error);
        return new ComboVJSearchBestRatesLoadError(error);
      }
    }
  );
  //   @Effect() loadComboVJSearchBestRates$ = this.actions$.pipe(
  //     ofType<ComboVJLoadSearchBestRates>(
  //       SearchBestRatesActionTypes.ComboVJLoadSearchBestRates
  //     ),
  //     map(action => action.payload),
  //     switchMap(payload => {
  //       if (payload.searchCode) {
  //         return this.hotelV3ResourceService
  //           .searchBestRatesUsingGET(
  //             payload.searchCode,
  //             payload.searchType,
  //             payload.language,
  //             payload.currency,
  //             payload.checkIn,
  //             payload.checkOut,
  //             payload.paxInfos,
  //             payload.searchId,
  //             payload.supplier,
  //             payload.filterHotelName,
  //             payload.filterHotelCategories,
  //             payload.filterBreakfastIncluded,
  //             payload.filterFromPrice,
  //             payload.filterToPrice,
  //             payload.filterFromStarRating,
  //             payload.filterToStarRating,
  //             payload.filterFromGuestRating,
  //             payload.filterToGuestRating,
  //             payload.filterPropertyAmenities,
  //             payload.filterRoomAmenities,
  //             payload.filterRoomViews,
  //             payload.filterThemes,
  //             payload.filterMealPlans,
  //             payload.filterGeoDistanceLat,
  //             payload.filterGeoDistanceLon,
  //             payload.filterGeoDistanceMeters,
  //             payload.sortField,
  //             payload.sortOrder,
  //             payload.pageNumber,
  //             payload.pageSize
  //           )
  //           .map(
  //             result => ({
  //               type: SearchBestRatesActionTypes.ComboVJSearchBestRatesLoaded,
  //               payload: result
  //             }),
  //             catchError(error => {
  //               console.log(error);
  //               return null;
  //             })
  //           );
  //       }
  //     })
  //   );

  constructor(
    private actions$: Actions,
    private hotelV3ResourceService: HotelResourceV3Service,
    private dataPersistence: DataPersistence<SearchBestRatesPartialState>
  ) {}
}
