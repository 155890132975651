import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { SearchBestRatesPartialState } from './search-best-rates.reducer';
import { searchBestRatesQuery } from './search-best-rates.selectors';
import {
  ComboVJLoadSearchBestRates,
  ComboVJSearchBestRatesReset
} from './search-best-rates.actions';
import { HotelPayload, LoadHotelSearchBestRates } from '@gtd/hotels/data-access/store';

@Injectable()
export class ComboSearchBestRatesFacade {
  loaded$ = this.store.pipe(select(searchBestRatesQuery.getLoaded));
  allSearchBestRates$ = this.store.pipe(
    select(searchBestRatesQuery.getAllSearchBestRates)
  );
  selectedSearchBestRates$ = this.store.pipe(
    select(searchBestRatesQuery.getSelectedSearchBestRates)
  );

  constructor(private store: Store<SearchBestRatesPartialState>) {}

  loadAll(hotelSearchReq: HotelSearchReq, loadMore?: boolean) {
    this.store.dispatch(new ComboVJLoadSearchBestRates(hotelSearchReq, loadMore));
  }

  searchResetAll() {
    this.store.dispatch(new ComboVJSearchBestRatesReset());
  }
}
export interface HotelSearchReq {
  searchCode: string;
  locationName?: string;
  searchType:
    | 'CONTINENT'
    | 'COUNTRY'
    | 'PROVINCE_STATE'
    | 'HIGH_LEVEL_REGION'
    | 'MULTI_CITY_VICINITY'
    | 'CITY'
    | 'NEIGHBORHOOD'
    | 'AIRPORT'
    | 'POINT_OF_INTEREST'
    | 'TRAIN_STATION'
    | 'METRO_STATION'
    | 'HOTEL';
  language: 'vi' | 'en';
  currency: 'VND' | 'USD';
  checkIn: string;
  checkOut: string;
  paxInfos: Array<string>;
  searchId?: string;
  supplier?: 'EXPEDIA' | 'AXISROOM';
  targetSupplier?: 'EXPEDIA' | 'AXISROOM';
  filterHotelName?: string;
  filterHotelCategories?: Array<string>;
  filterBreakfastIncluded?: boolean;
  filterFromPrice?: number;
  filterToPrice?: number;
  filterFromDistance?: number;
  filterToDistance?: number;
  filterStarRating?: Array<string>;
  filterFromGuestRating?: number;
  filterToGuestRating?: number;
  filterPropertyAmenities?: Array<string>;
  filterRoomAmenities?: Array<string>;
  filterRateAmenities?: Array<string>;
  filterRoomViews?: Array<string>;
  filterThemes?: Array<string>;
  filterMealPlans?: Array<string>;
  filterBedTypes?: Array<string>;
  filterGeoDistanceLat?: number;
  filterGeoDistanceLon?: number;
  filterGeoDistanceMeters?: number;
  sortField?: 'order' | 'price' | 'starRating' | 'guestRating';
  sortOrder?: 'ASC' | 'DESC';
  pageNumber?: number;
  pageSize?: number;
}
