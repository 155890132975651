import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BannerTypeService } from "@gtd/api-services/newface-banner";
import { GaTrackingService } from "@gtd/ga-tracking";
import { fadeIn } from "@gtd/helpers";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BannerFacade } from "../store/banner/banner.facade";

@Component({
  selector: 'gtd-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss'],
  animations: [fadeIn]
})
export class SearchSectionComponent implements OnInit {
  @Input() selectedIndex: number;
  @Input() comboActive$: Observable<any>;
  @Input() currentSlug$: Observable<string>;
  comboActive = false;
  typeActive: number = 0;
  disableRipple = true;
  allBanner$ = this.bannerFacade.allBanner$;
  tabList = [
    {
      title: 'flight',
      icon: 'flight',
      eventName: 'ticket_screen',
      slug: 've-may-bay',
      bannerId: '101'
    },
    {
      title: 'hotel',
      icon: 'hotel',
      eventName: 'hotel_screen',
      slug: 'khach-san',
      bannerId: '102'
    },
    {
      title: 'combo',
      subTitle: 'combovj.name',
      icon: 'combo',
      eventName: 'combo_screen',
      slug: 'combo-du-lich',
      bannerId: '103'
    },
    // TOURS
    {
      title: 'activity',
      icon: 'tour',
      slug: 'vui-choi-giai-tri/diem-den/tat-ca',
    }
  ];
  isProd: boolean;
  deviceId: number = 76;
  banner: any;
  bannerList: any = null;
  config: any;

  constructor(
    public deviceDetector: DeviceDetectorService,
    public router: Router,
    private _gaTrackingService: GaTrackingService,
    private deviceService: DeviceDetectorService,
    private bannerTypeService: BannerTypeService,
    private bannerFacade: BannerFacade
  ) {}

  onTabChanged(event) {
    this.typeActive = event.index;
  }
  track(eventName: string) {
    this._gaTrackingService.track(eventName);
  }

  ngOnInit() {
    this.isProd = environment.production;
    if (!this.isProd) {
      this.deviceId = 100;
    }
    this.tabList.forEach(item => {
      this.bannerFacade.loadAll({
        deviceId: this.deviceId,
        bannerId: +item.bannerId,
        type: item.title
      });
    });
    this.comboActive$.subscribe(response => {
      if (response && Object.keys(response)) {
        this.comboActive = response.combo;
        this.currentSlug$.subscribe(currentSlug => {
          this.bannerList = null;

          const index = this.tabList.findIndex(tab => tab.slug === currentSlug);

          this.typeActive = index < 0 ? 0 : index;

          const currentTab = this.tabList[index < 0 ? 0 : index];
          this.allBanner$.subscribe(allBanner => {
            if (allBanner) {
              this.bannerList = allBanner[currentTab.title];
              this.config = {
                slidesPerView: 1,
                spaceBetween: 15,
                // lazy: true,
                speed: 1000,
                autoplay: {
                  delay: 5000,
                  disableOnInteraction: false
                },
                effect: 'fade',
                navigation: {
                  nextEl: '.search-section .swiper-next',
                  prevEl: '.search-section .swiper-prev'
                }
              };
            }
          });
        });
      }
    });
  }
}
