import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {AvailablePlansPartialState} from './available-plans.reducer';
import {AvailablePlansActionTypes, AvailablePlansLoadError, LoadAvailablePlans} from './available-plans.actions';
import {map} from "rxjs/operators";
import {FlexiApiResourceService} from "@gtd/api-services/service-inventory";

@Injectable()
export class AvailablePlansEffects {
  @Effect() loadAvailablePlans$ = this.dataPersistence.fetch(
    AvailablePlansActionTypes.LoadAvailablePlans,
    {
      run: (action: LoadAvailablePlans, state: AvailablePlansPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.flexiApiResourceService.getInsurancePlansUsingGET(
          action.payload.departureDate,
          action.payload.fromLocation,
          action.payload.toLocation,
          action.payload.adult,
          action.payload.child,
          action.payload.infant,
          action.payload.planId,
          action.payload.bookingNumber,
          [],
          action.payload.returnDate,
        ).pipe(
            map(result => ({
              type: AvailablePlansActionTypes.AvailablePlansLoaded,
              payload: result
            }))
          );
      },
      onError: (action: LoadAvailablePlans, error) => {
        console.error('Error', error);
        return new AvailablePlansLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private flexiApiResourceService: FlexiApiResourceService,
    private dataPersistence: DataPersistence<AvailablePlansPartialState>
  ) {}
}
