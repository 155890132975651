import {Component, Input, OnInit} from '@angular/core';
import { BookingInfo, GroupPricedItinerary, OriginDestinationOption } from '@gtd/b2c-client';
import {environment} from "@env/environment";
import {MatDialog} from "@angular/material";
import {FlightIntDetailDialogComponent} from "../../flight-int-detail-dialog/flight-int-detail-dialog.component";

@Component({
  selector: 'gtd-box-item-int',
  templateUrl: './box-item-int.component.html',
  styleUrls: ['./box-item-int.component.scss']
})
export class BoxItemIntComponent implements OnInit {
  @Input() groupPricedItineraries: Array<GroupPricedItinerary>;
  @Input() bookingNumber: string;
  @Input() bookingInfo: BookingInfo;
  @Input() isBookingDetail: boolean;

  constructor(
    private _dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  displayAirlineLogo(airlineCode: string) {
    if (
      airlineCode === 'VN' ||
      airlineCode === 'VJ' ||
      airlineCode === 'QH' ||
      airlineCode === 'BL'
    )
      return environment.cdn.logoAirline + airlineCode + '.svg';
    else return environment.cdn.logoAirline + airlineCode + '.gif';
  }

  openDetailDialog(flightItem: OriginDestinationOption) {
    this._dialog.open(FlightIntDetailDialogComponent, {
      data: {
        flightItem: flightItem,
        bookingNumber: this.bookingNumber,
        roundType: this.groupPricedItineraries[0].roundType,
        groupId: this.groupPricedItineraries[0].groupId
      },
      position: {
        right: '0',
        top: '0'
      },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__slideInRight',
        'animate__faster',
        'flight-detail-dialog__deep'
      ],
      disableClose: true
    });
  }
}
