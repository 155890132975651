import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSimItemDetailComponent } from './modal-sim-item-detail/modal-sim-item-detail.component';
import { ModalSimOptionComponent } from './modal-sim-option/modal-sim-option.component';
import {
  MatButtonModule, MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule, MatSelectModule,
  MatTableModule,
  MatTabsModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ServiceInventoryModule } from '@gtd/api-services/service-inventory';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    FontAwesomeModule,
    MatDialogModule,
    ReactiveFormsModule,
    RouterModule,
    ServiceInventoryModule
  ],
  declarations: [ModalSimItemDetailComponent, ModalSimOptionComponent],
  exports: [ModalSimItemDetailComponent, ModalSimOptionComponent],
  entryComponents: [ModalSimItemDetailComponent, ModalSimOptionComponent]
})
export class ModalSimModule {}
