import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ComboBookingSummaryPanelComponent
} from './booking-summary-panel/booking-summary-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { BoxFlightBookingInfoModule } from '@gtd/components/box-flight-booking-info';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GTypographyModule } from '@gotadi/design-system';
import { MatDialogModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatTabsModule } from '@angular/material';
import {
  BookingJourneyDetailComponent
} from './booking-summary-panel/booking-journey-detail/booking-journey-detail.component';
import { AllPipesModule } from '@gtd/pipes/all-pipes';
import {
  BookingIntJourneyDetailComponent
} from './booking-summary-panel/booking-int-journey-detail/booking-int-journey-detail.component';
import { ComponentsGiftServiceModule } from '@gtd/components/gift-service';
import { PanelBookingDetailComponent } from './booking-summary-panel/panel-booking-detail/panel-booking-detail.component';
import { BoxPassengerBaggageModule } from '@gtd/components/box-passenger-baggage';
import { ModalSimModule } from '@gtd/components/modal-sim';
import { CurrencyExchangeModule } from '@gtd/pipes/currency-exchange';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    BoxFlightBookingInfoModule,
    TranslateModule,
    FlexLayoutModule,
    FontAwesomeModule,
    GTypographyModule,
    MatMenuModule,
    AllPipesModule,
    MatProgressBarModule,
    MatIconModule,
    ComponentsGiftServiceModule,
    MatTabsModule,
    BoxPassengerBaggageModule,
    MatDialogModule,
    ModalSimModule,
    CurrencyExchangeModule
  ],
  declarations: [
    ComboBookingSummaryPanelComponent,
    BookingJourneyDetailComponent,
    BookingIntJourneyDetailComponent,
    PanelBookingDetailComponent
  ],
  exports: [ComboBookingSummaryPanelComponent, BookingIntJourneyDetailComponent]
})
export class BookingSummaryPanelModule {}
