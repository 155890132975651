import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { ControlContainer } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import {
  DatepickerService,
  LunarDateExport
} from 'libs/components/datepicker/src/lib/datepicker.service';
import {
  CustomDatepickerI18n,
  TranslateDatePicker
} from '@gtd/components/datepicker';

@Component({
  selector: 'gtd-combo-datepicker-only',
  templateUrl: './combo-datepicker-only.component.html',
  styleUrls: ['./combo-datepicker-only.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    TranslateDatePicker,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class ComboDatepickerOnlyComponent implements OnInit, OnChanges {
  @Input() displayMonths: number;

  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Input() defaultFromDate: Date;
  @Input() defaultToDate: Date;

  @Input() labelFormDate: string;
  @Input() labelToDate: string;
  @Input() defaultChange;
  @Input() type: 'hotel' | 'flight';
  @Input() isEnable: boolean;
  @Input() roundType;
  @Input() roundTypeString = 'ONEWAY';
  @Input() submited = false;

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  minNgbDate: NgbDate;
  maxNgbDate: NgbDate;
  forcus: 'to' | 'from';

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private datepickerService: DatepickerService,
    public controlContainer: ControlContainer,
    private translate: TranslateService,
    private translateDatePicker: TranslateDatePicker,
    private deviceService: DeviceDetectorService
  ) {
    setTimeout(() => {
      this.translate.onLangChange.subscribe(lang => {
        this.translateDatePicker.language = lang.lang;
      });
    });
  }

  ngOnInit() {
    // this.displayMonths =
    //   this.deviceService.isDesktop() || this.deviceService.isTablet() ? 2 : 1;
    if(this.controlContainer.value) {
      if(this.controlContainer.value.toDate) {
        this.toDate = this.convertNgbDate(this.controlContainer.value.toDate);
      }
    }

    if (this.minDate) {
      this.minNgbDate = this.convertNgbDate(this.minDate);
    }
    if (this.maxDate) {
      this.maxNgbDate = this.convertNgbDate(this.maxDate);
    }
    if (this.defaultFromDate) {
      this.fromDate = this.convertNgbDate(this.defaultFromDate);
    }
    if (this.defaultToDate) {
      this.toDate = this.convertNgbDate(this.defaultToDate);
    }
    this.defaultChange.subscribe(value => {
      this.fromDate = this.convertNgbDate(value.fromDate);
      if (value.toDate) {
        this.toDate = this.convertNgbDate(value.toDate);
      }
      if (this.fromDate.after(this.toDate)) {
        this.toDate = this.convertNgbDate(
          new Date(
            Date.UTC(
              this.fromDate.year,
              this.fromDate.month - 1,
              this.fromDate.day + 1
            )
          )
        );
        this.controlContainer.control.setValue({
          fromDate: new Date(
            Date.UTC(
              this.fromDate.year,
              this.fromDate.month - 1,
              this.fromDate.day
            )
          ),
          toDate: new Date(
            Date.UTC(
              this.toDate.year,
              this.toDate.month - 1,
              this.toDate.day + 1
            )
          )
        });
      }
      this.setMaxDate(value.fromDate);
      this.setDefaultToDate();
    });
    this.roundType.subscribe(value => {
      this.roundTypeString = value;
      this.setDefaultToDate();
    });
    this.setMaxDate(this.defaultFromDate);
  }

  setDefaultToDate() {
    if (
      this.toDate &&
      this.toDate.after(this.maxNgbDate) &&
      this.roundTypeString === 'ONEWAY'
    ) {
      this.toDate = this.maxNgbDate;
      this.onDateSelection(this.toDate);
    }
  }
  setMaxDate(date: Date) {
    const defaultMaxDate = new Date(date);
    defaultMaxDate.setDate(defaultMaxDate.getDate() + 27);
    this.maxNgbDate = this.convertNgbDate(defaultMaxDate);
    this.maxDate = defaultMaxDate;
  }
  onDateSelection(date: NgbDate) {
    this.toDate = date;
    this.forcus = 'to';
    this.controlContainer.control.setValue({
      fromDate: new Date(
        Date.UTC(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
      ),
      toDate: new Date(
        Date.UTC(this.toDate.year, this.toDate.month - 1, this.toDate.day)
      )
    });
  }

  convertNgbDate(date: Date): NgbDate {
    return this.calendar.getNext(
      {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        after(other: NgbDateStruct): boolean {
          return true;
        },
        before(other: NgbDateStruct): boolean {
          return true;
        },
        equals(other: NgbDateStruct): boolean {
          return true;
        }
      },
      'd',
      0
    );
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }
  checkPosition(date: NgbDate) {
    if (date.equals(this.fromDate) && date.equals(this.toDate)) {
      return 'both';
    } else if (date.equals(this.fromDate)) {
      return 'first';
    } else if (date.equals(this.toDate)) {
      return 'last';
    }
  }
  isHovered(date: NgbDate) {
    if (this.hoveredDate && !this.isValid(this.hoveredDate)) {
      return false;
    }
    if (this.toDate) {
      return date.after(this.toDate) && date.before(this.hoveredDate);
    }
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isValid(date: NgbDate) {
    return (
      (date.before(this.maxNgbDate) && date.after(this.minNgbDate)) ||
      date.equals(this.maxNgbDate) ||
      date.equals(this.minNgbDate)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  isPreviousDate(date: NgbDate) {
    if (this.hoveredDate && !this.isValid(this.hoveredDate)) {
      return false;
    }
    if (this.hoveredDate && this.hoveredDate.after(this.toDate))
      return date.equals(this.toDate);
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  getDateLunar(date: NgbDate): LunarDateExport {
    const offset = new Date().getTimezoneOffset();
    return this.datepickerService.convertSolar2Lunar(
      date.day,
      date.month,
      date.year,
      +7
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultToDate && !changes.defaultToDate.currentValue) {
      this.toDate = null;
    }
  }
}
