import {Action} from '@ngrx/store';
import {ListResponseOfSSRItemDTO, OtaResultOfListOfSSRItemDTO} from "@gtd/api-services/service-inventory";

export enum AvailablePlansActionTypes {
  LoadAvailablePlans = '[AvailablePlans] Load AvailablePlans',
  AvailablePlansLoaded = '[AvailablePlans] AvailablePlans Loaded',
  DestroyAvailablePlans = '[AvailablePlans] Destroy Available Plans',
  AvailablePlansLoadError = '[AvailablePlans] AvailablePlans Load Error'
}

export class LoadAvailablePlans implements Action {
  readonly type = AvailablePlansActionTypes.LoadAvailablePlans;
  constructor(public payload: {departureDate: string, fromLocation: string, toLocation: string, adult?: number, child?: number, infant?: number, returnDate?: string, bookingNumber?: string, planId?: string}) {}
}

export class AvailablePlansLoadError implements Action {
  readonly type = AvailablePlansActionTypes.AvailablePlansLoadError;
  constructor(public payload: any) {}
}

export class AvailablePlansLoaded implements Action {
  readonly type = AvailablePlansActionTypes.AvailablePlansLoaded;
  constructor(public payload: OtaResultOfListOfSSRItemDTO) {}
}

export class DestroyAvailablePlans implements Action {
  readonly type = AvailablePlansActionTypes.DestroyAvailablePlans;
  constructor() {}
}


export type AvailablePlansAction =
  | LoadAvailablePlans
  | AvailablePlansLoaded
  | DestroyAvailablePlans
  | AvailablePlansLoadError;

export const fromAvailablePlansActions = {
  LoadAvailablePlans,
  AvailablePlansLoaded,
  DestroyAvailablePlans,
  AvailablePlansLoadError
};
