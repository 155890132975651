import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyAllRate } from '@gtd/b2c-client';
import { MatDialog } from '@angular/material';
import { MapPanelDetailComponent } from '../map-panel-detail/map-panel-detail.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SlugifyPipe } from '../../../pipe/slug.pipe';

@Component({
  selector: 'gtd-hotel-information',
  templateUrl: './hotel-information.component.html',
  styleUrls: ['./hotel-information.component.scss'],
  providers: [SlugifyPipe]
})
export class HotelInformationComponent implements OnInit {
  @Input() hotelDetail: PropertyAllRate;
  @Output() scrollToSection: EventEmitter<any> = new EventEmitter<any>();
  counter = Array;
  numbItemAnimes: number;
  aroundArea: any;
  aroundAreaArr: string[];
  locationFrom;
  locationTo;
  appName = localStorage.getItem('appName');

  constructor(
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private _slugifyPipe: SlugifyPipe
  ) {}

  ngOnInit() {
    this.locationFrom = localStorage.getItem('comboFrom')
      ? JSON.parse(localStorage.getItem('comboFrom'))[0]
      : '';
    this.locationTo = localStorage.getItem('comboTo')
      ? JSON.parse(localStorage.getItem('comboTo'))[0]
      : '';

    this.numbItemAnimes = this.deviceService.isDesktop() ? 11 : 4;

    this.aroundArea = this.hotelDetail.descriptions
      ? this.hotelDetail.descriptions.find(
          description => description.name === 'attractions'
        )
      : '';
    if (this.aroundArea) {
      this.aroundAreaArr = this.aroundArea.value.split('<br />');
    }
  }

  viewMapPanelDetail() {
    this.dialog.open(MapPanelDetailComponent, {
      data: {
        hotelDetail: this.hotelDetail
      },
      position: {
        top: '0px',
        left: '0px'
      },
      panelClass: 'popupMapPanel'
    });
  }
  sectionSelected(id: string) {
    this.scrollToSection.emit(id);
  }
  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    const location = document.location.origin;
    selBox.value =
      val === 'hotel'
        ? `${location}/khach-san/${this._slugifyPipe.transform(
          this.hotelDetail.propertyName
        )}-${this.hotelDetail.masterPropertyId}`
        : `${location}/khach-san/${this._slugifyPipe.transform(
          this.hotelDetail.propertyName
        )}-${this.hotelDetail.masterPropertyId}?from=${
          this.locationFrom.code
        }&to=${this.locationTo.code}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
