import {
  SearchBestRatesAction,
  SearchBestRatesActionTypes
} from './search-best-rates.actions';
import { PageResult, PropertyAvailable, SearchBestRatesResult } from '@gtd/b2c-client';

export const SEARCHBESTRATES_FEATURE_KEY = 'comboVJSearchBestRates';

/**
 * Interface for the 'SearchBestRates' data used in
 *  - SearchBestRatesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface SearchBestRatesState {
  result: SearchBestRatesResult;
  pageable: PageResult;
  selectedId?: string | number;
  loaded: boolean;
  error?: any;
}

export interface SearchBestRatesPartialState {
  readonly [SEARCHBESTRATES_FEATURE_KEY]: SearchBestRatesState;
}

export const initialState: SearchBestRatesState = {
  result: null,
  pageable: null,
  loaded: false,
};

export function searchBestRatesReducer(
  state: SearchBestRatesState = initialState,
  action: SearchBestRatesAction
): SearchBestRatesState {
  switch (action.type) {
    case SearchBestRatesActionTypes.ComboVJSearchBestRatesLoaded: {
      state = ({
        ...state,
        result: {
          ...state.result,
          propertyAvailable:  action.loadMore? [
            ...(state.result && state.result.propertyAvailable && state.result.propertyAvailable.length? state.result.propertyAvailable: []),
            ...(action.payload && action.payload.propertyAvailable && action.payload.propertyAvailable.length? action.payload.propertyAvailable: [])
          ]: (action.payload && action.payload.propertyAvailable? action.payload.propertyAvailable: []),
          pageResult: action.payload.pageResult,
          searchId: action.payload.searchId
        },
        loaded: true,
      });
      break;
    }
    case SearchBestRatesActionTypes.ComboVJSearchBestRatesLoadError: {
      state = ({
        ...state,
        loaded: true,
      });
      break;
    }
    case SearchBestRatesActionTypes.ComboVJSearchBestRatesReset: {
      state = initialState;
      break;
    }
  }
  return state;
}
