import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboFlightBookingComponent } from './combo-flight-booking/combo-flight-booking.component';
import { FlightBookingModule } from '@gtd/flights/ui/flight-booking';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FlightBookingModule,
    TranslateModule
  ],
  declarations: [ComboFlightBookingComponent],
  exports: [ComboFlightBookingComponent]
})
export class ComboFlightBookingModule {}
