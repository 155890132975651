import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material";
import {TcbFormCustomerComponent} from "../tcb-form-customer/tcb-form-customer.component";
import {GroupBooking} from "@gtd/b2c-client";
import {TcbFormCustomerListComponent} from "../tcb-form-customer-list/tcb-form-customer-list.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'gtd-tcb-fx-option',
  templateUrl: './tcb-fx-option.component.html',
  styleUrls: ['./tcb-fx-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TcbFxOptionComponent),
      multi: true
    }
  ]
})
export class TcbFxOptionComponent implements OnInit {

  @Input() inputInfoCtrl: any;
  @Input() bookingDetail: GroupBooking;

  @Input() dataPax: any;
  typeOption: any;

  constructor(
    public dialog: MatDialog,
  ) {

  }

  ngOnInit() {

  }

  addCustomer(type?: any) {
    const dialogRef = this.dialog.open(TcbFormCustomerComponent, {
      width: '744px',
      position: {
        top: '100px'
      },
      data: {
        inputInfoCtrl: this.inputInfoCtrl,
        bookingDetail: this.bookingDetail,
        dataPax: this.dataPax
      },
    });
    dialogRef.afterClosed().subscribe(data => {
      if(data && data.customer) {
        this.typeOption = 'SINGLE';
        this.onChange({
          type: 'SINGLE',
          data: [data.customer]
        });
        this.onTouched();
      }
    });
  }
  addCustomerList(type?: any) {
    const dialogRef = this.dialog.open(TcbFormCustomerListComponent, {
      width: '744px',
      position: {
        top: '100px'
      },
      data: {
        inputInfoCtrl: this.inputInfoCtrl,
        bookingDetail: this.bookingDetail,
        dataPax: this.dataPax
      },
    });
    dialogRef.afterClosed().subscribe(data => {
      if(data && data.customers && data.customers.length) {
        this.typeOption = 'MULTIPLE';
        this.onChange({
          type: 'MULTIPLE',
          data: data.customers
        });
        this.onTouched();
      }
    });
  }


  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  writeValue(obj: any[]): void {
  }

  cancelFx(event: any, type: string) {
    event.stopPropagation();
    this.onChange({
      type: type,
      data: null
    });
    this.onTouched();
  }
}
