import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourDetailSummaryComponent } from './tour-detail-summary.component';
import { MatCardModule, MatMenuModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { TourServiceInformationModule } from '@gtd/extra/tour/ui/tour-service-information';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    MatMenuModule,
    TourServiceInformationModule
  ],
  declarations: [TourDetailSummaryComponent],
  exports: [TourDetailSummaryComponent]
})
export class TourDetailSummaryModule {}
