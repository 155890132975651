import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ModalSimItemDetailComponent } from '../modal-sim-item-detail/modal-sim-item-detail.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'gtd-modal-sim-option',
  templateUrl: './modal-sim-option.component.html',
  styleUrls: ['./modal-sim-option.component.scss']
})
export class ModalSimOptionComponent implements OnInit {
  isMemberCardType = 0;
  panelOpenState: number = 0;
  simForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalSimOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService,
  ) {}

  ngOnInit() {
    if (this.data.simGroupedData && this.data.simGroupedData.length) {
      this.mappingDefaultSelected();
      this.simForm = this.fb.group({
        simGroupedData: this.fb.array(
          this.data.simGroupedData.map((group: any) =>
            this.fb.group({
              countryNames: [group.groupName],
              lists: this.fb.array(
                group.lists.map((item: any) =>
                  this.fb.group({
                    selectedItem: [
                      this.mappingDataSelected([item]) || {
                        code: item.code,
                        name: item.name,
                        validity: item.validity,
                        dataLimit: item.dataLimit,
                        originalPrice: item.originalPrice,
                        totalDiscount: item.totalDiscount,
                        additionalProperties: item.additionalProperties || [],
                        countryCodes: item.countryCodes[0] || null,
                        countryNames: item.countryNames[0] || null,
                        regionCodes: item.regionCodes[0] || null,
                        regionNames: item.regionNames[0] || null,
                        quantity: 0
                      }
                    ],
                    code: [item.code],
                    name: [item.name],
                    validity: [item.validity],
                    dataLimit: [item.dataLimit],
                    originalPrice: [item.originalPrice],
                    totalDiscount: [item.totalDiscount],
                    additionalProperties: [item.additionalProperties],
                    countryCodes: [item.countryCodes[0] || null],
                    countryNames: [item.countryNames[0] || null],
                    regionCodes: [item.regionCodes[0] || null],
                    regionNames: [item.regionNames[0] || null],
                    quantity: 0
                  })
                )
              )
            })
          )
        )
      });
    }
  }

  mappingDefaultSelected() {
    if (
      this.data.allItemSim &&
      this.data.allItemSim.length &&
      this.data.simSelected &&
      this.data.simSelected.length
    ) {
      const itemSelectedCodes = this.data.simSelected.map(item => item.simCode);
      this.simSelectedArrays = this.data.allItemSim
        .map(simItem => {
          if (itemSelectedCodes.includes(simItem.code)) {
            const matchedItem = this.data.simSelected.find(
              item => item.simCode === simItem.code
            );
            return {
              ...simItem,
              quantity: matchedItem.quantity || 0
            };
          }
          return null;
        })
        .filter(item => item !== null); // Loại bỏ các giá trị null
    }
    return null;
  }

  mappingDataSelected(items: any[]) {
    if (this.data.simSelected && this.data.simSelected.length) {
      const itemSelectedCodes = this.data.simSelected.map(item => item.simCode);

      const simSelected = items
        .map(simSt => {
          if (itemSelectedCodes.includes(simSt.code)) {
            const matchedItem = this.data.simSelected.find(
              item => item.simCode === simSt.code
            );
            return {
              ...simSt,
              quantity: matchedItem.quantity || 0
            };
          }
          return null;
        })
        .filter(item => item !== null);

      this.totalAmount = this.data.simSelected.reduce(
        (sum, item) => sum + item.originalPrice * (item.quantity || 0),
        0
      );
      return simSelected.length ? simSelected[0] : null;
    }
    return null;
  }

  displayFn(item: any): string {
    return item ? item.code : '';
  }

  get simGroupedDataArray(): FormArray {
    return this.simForm.get('simGroupedData') as FormArray;
  }

  listsArray(groupIndex: number): FormArray {
    return this.simGroupedDataArray.at(groupIndex).get('lists') as FormArray;
  }

  itemsArray(groupIndex: number, listIndex: number): FormArray {
    return this.listsArray(groupIndex)
      .at(listIndex)
      .get('items') as FormArray;
  }

  closeModal() {
    this.dialogRef.close();
  }

  detailItemSim(selectedItem: any) {
    const dialogRef = this.dialog.open(ModalSimItemDetailComponent, {
      width: this.deviceService.isMobile()? '100%': '745px',
      maxWidth: this.deviceService.isMobile()? '100%': '80vw',
      position: {
        top: this.deviceService.isMobile()? '0' :'100px'
      },
      data: {
        simDetail: selectedItem
      },
      panelClass: this.deviceService.isMobile()? [
        'modal-sim-detail',
        'animate__animated',
        'animate__slideInUp',
        'animate__faster',
        'full-height',
        'full-screen'
      ]: 'modal-sim-detail',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data) return false;
    });
  }

  compareFn = (a: any, b: any): boolean => {
    return a && b ? a.dataLimit === b.dataLimit && a.code === b.code : a === b;
  };

  simSelectedArrays = [];
  totalAmount = 0;

  updateQuantity(selectedItem: any, type: 'increase' | 'decrease') {
    if (type === 'decrease') {
      selectedItem.quantity--;
    } else {
      selectedItem.quantity++;
    }

    this.simSelectedArrays = this.simGroupedDataArray.value.flatMap(
      (group: any) =>
        group.lists
          .filter((list: any) => list.selectedItem.quantity > 0)
          .map((list: any) => list.selectedItem)
    );
    this.totalAmount = this.simSelectedArrays.reduce(
      (sum, item) => sum + item.originalPrice * item.quantity,
      0
    );
  }

  confirmOrderSim() {
    this.dialogRef.close({ simSelected: this.simSelectedArrays });
  }
}
