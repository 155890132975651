import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { FarerulesFacade } from '../flight-search/farerules/+state/farerules.facade';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';

@Component({
  selector: 'gtd-flight-confirm-dialog',
  templateUrl: './flight-confirm-dialog.component.html',
  styleUrls: ['./flight-confirm-dialog.component.scss']
})
export class FlightConfirmDialogComponent implements OnInit {
  flightBooking$ = this._comboSelectedService.flightListSelected$;
  flightAutoPrice$ = this._comboSelectedService.priceOfFlightAutoSelected$;
  flightSelectedDone$ = this._comboSelectedService.flightSelectedDone$;
  totalPrice = 0;

  constructor(
    private _comboSelectedService: ComboSelectedService,
    private farerulesFacade: FarerulesFacade
  ) {}

  ngOnInit() {
    this.flightSelectedDone$.take(1).subscribe(done => {
      if (!done && this._comboSelectedService.flightReset) {
        this._comboSelectedService.resetFLight();
        const flightBookingFromStorage = JSON.parse(
          localStorage.getItem('flightBooking')
        );
        for (const item of flightBookingFromStorage) {
          this._comboSelectedService.selectFlight(
            item.searchId,
            item.pricedItinerary,
            item.itemFlight,
            item.type
          );
        }
      }
    });
    combineLatest(this.flightBooking$, this.flightAutoPrice$).subscribe(
      data => {
        const [booking, price] = data;
        if (booking && booking.length && price && price.length) {
          if(booking[0].itemFlight.flightType === "INTERNATIONAL" && booking.length > 1) {
            this.totalPrice = (booking[1].pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
                .totalFare.amount / booking[1].pricedItinerary.airItineraryPricingInfo.adultFare
              .passengerTypeQuantities.quantity)-price[1]
          } else {
            for (let i = 0; i < booking.length; i++) {
              this.totalPrice +=
                booking[i].pricedItinerary.airItineraryPricingInfo.adultFare
                  .passengerFare.totalFare.amount /
                booking[i].pricedItinerary.airItineraryPricingInfo.adultFare
                  .passengerTypeQuantities.quantity -
                price[i];
            }
          }
        }
      }
    );
  }

  changeTab() {
    this.farerulesFacade.resetFareRules();
  }
}
