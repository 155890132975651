import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {NxModule} from '@nrwl/angular';
import {environment} from '@env/environment';
import {
  ApiDelayInsuranceService,
  BASE_PATH,
  FlexiApiResourceService,
  SimApiResourceService
} from '@gtd/api-services/service-inventory';

import {
  AVAILABLEPLANS_FEATURE_KEY,
  availablePlansReducer,
  initialState as availablePlansInitialState
} from './available-plans/available-plans.reducer';
import {AvailablePlansEffects} from './available-plans/available-plans.effects';
import {AvailablePlansFacade} from './available-plans/available-plans.facade';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(AVAILABLEPLANS_FEATURE_KEY, availablePlansReducer, {
      initialState: availablePlansInitialState
    }),
    EffectsModule.forFeature([AvailablePlansEffects]),
    NxModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.inventoryService },
    ApiDelayInsuranceService,
    AvailablePlansFacade,
    FlexiApiResourceService,
    SimApiResourceService
  ]
})
export class ServiceInventoryModule {}
