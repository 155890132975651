import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AvailablePlansPartialState } from './available-plans.reducer';
import { availablePlansQuery } from './available-plans.selectors';
import {DestroyAvailablePlans, LoadAvailablePlans} from './available-plans.actions';

@Injectable()
export class AvailablePlansFacade {
  loaded$ = this.store.pipe(select(availablePlansQuery.getLoaded));
  allAvailablePlans$ = this.store.pipe(
    select(availablePlansQuery.getAllAvailablePlans)
  );
  selectedAvailablePlans$ = this.store.pipe(
    select(availablePlansQuery.getSelectedAvailablePlans)
  );

  constructor(private store: Store<AvailablePlansPartialState>) {}

  destroyPlan() {
    this.store.dispatch(new DestroyAvailablePlans());
  }
  loadAvailablePlans(
    departureDate: string,
    fromLocation: string,
    toLocation: string,
    adult?: number,
    child?: number,
    infant?: number,
    returnDate?: string,
    bookingNumber?: string,
    planId?: string
  ) {
    this.store.dispatch(new LoadAvailablePlans(
      {
        departureDate: departureDate,
        fromLocation: fromLocation,
        toLocation: toLocation,
        adult: adult,
        child: child,
        infant: infant,
        returnDate: returnDate,
        bookingNumber: bookingNumber,
        planId: planId
      }
    ));
  }
}
