import { Component, Input, OnInit } from '@angular/core';
import { TourRatePlanAvailable } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-tour-service-information',
  templateUrl: './tour-service-information.component.html',
  styleUrls: ['./tour-service-information.component.scss']
})
export class TourServiceInformationComponent implements OnInit {
  @Input() ratePlan: TourRatePlanAvailable;
  @Input() policy: string;
  ratePlanAmenities: any[];
  constructor() {}

  ngOnInit() {
    this.ratePlanAmenities = Object.keys(this.ratePlan.amenities).map(
      key => this.ratePlan.amenities[key]
    );
  }
}
