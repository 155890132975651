import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  AirTicketsResourceService,
  FareRules,
  FlightSegment,
  GroupPricedItinerary
} from '@gtd/b2c-client';
import { environment } from '@env/environment';
import { GaTrackingService } from '@gtd/ga-tracking';

@Component({
  selector: 'gtd-booking-journey-detail',
  templateUrl: './booking-journey-detail.component.html',
  styleUrls: ['./booking-journey-detail.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class BookingJourneyDetailComponent
  implements OnInit, AfterContentChecked {
  @Input() groupPricedItinerary: GroupPricedItinerary;
  @Input() bookingNumber: string;
  @Input() bookingDirectionIndex: number;
  flightSegments?: Array<FlightSegment>;

  isDetailJourney: boolean;
  panelOpenState: boolean;

  submitLoadMore: boolean = false;
  allFarerules: FareRules[];

  constructor(
    private cdref: ChangeDetectorRef,
    private airTicketsResourceService: AirTicketsResourceService,
    private _gaTrackingService: GaTrackingService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      if (this.groupPricedItinerary) {
        this.flightSegments = this.groupPricedItinerary.pricedItineraries[0].originDestinationOptions[0].flightSegments;
      }
    });
  }

  getFareRules() {
    this.submitLoadMore = true;
    this._gaTrackingService.track('F_FlightDetail_TapFlightCondition');

    if (this.bookingNumber) {
      this.airTicketsResourceService
        .retrieveAirFareRulesUsingGET(this.bookingNumber)
        .subscribe(allFarerules => {
          if (allFarerules) {
            this.submitLoadMore = false;
            this.allFarerules =
              allFarerules.bookedFareRules[
                this.bookingDirectionIndex
              ].fareRules;
          }
        });
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
}
