/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Amenity } from './amenity';
import { HotelImage } from './hotelImage';
import { PropertyCategory } from './propertyCategory';
import { TripAdvisor } from './tripAdvisor';

export interface PropertyAvailable {
  masterPropertyId: string;
  address?: Address;
  amenities?: Array<Amenity>;
  basePrice?: number;
  basePriceBeforePromo?: number;
  breakfastIncluded?: boolean;
  cancelFree?: boolean;
  currency?: PropertyAvailable.CurrencyEnum;
  images?: Array<HotelImage>;
  language?: PropertyAvailable.LanguageEnum;
  latitude?: number;
  longitude?: number;
  promo?: boolean;
  propertyCategory?: PropertyCategory;
  propertyId?: string;
  propertyName?: string;
  gifts?: PromoTag[];
  promoTags?: PromoTag[];
  promoDiscount?: PromoDiscount[];
  refundable?: boolean;
  reviewCount?: string;
  reviewRecommendPercent?: string;
  reviewScore?: string;
  stars?: string;
  supplier?: PropertyAvailable.SupplierEnum;
  tags?: Array<string>;
  taxAndServiceFree?: number;
  totalPrice?: number;
  totalRooms?: number;
  tripAdvisor?: TripAdvisor;
  availableType: any;
  distanceToCenter: any;
}
export interface PromoDiscount {
  basePriceBeforePromo: number;
  level: number;
  name: string;
}
export interface PromoTag {
  name: string;
  promoType: string;
  refId: string;
  show: boolean;
  value: string;
  checked?: boolean;
}
export namespace PropertyAvailable {
  export type CurrencyEnum = 'VND' | 'USD';
  export const CurrencyEnum = {
    VND: 'VND' as CurrencyEnum,
    USD: 'USD' as CurrencyEnum
  };
  export type LanguageEnum = 'vi' | 'en';
  export const LanguageEnum = {
    Vi: 'vi' as LanguageEnum,
    En: 'en' as LanguageEnum
  };
  export type SupplierEnum =
    | 'EXPEDIA'
    | 'AXISROOM'
    | 'BEDLINKER'
    | 'AGODA'
    | 'VINPEARL';
  export const SupplierEnum = {
    EXPEDIA: 'EXPEDIA' as SupplierEnum,
    AXISROOM: 'AXISROOM' as SupplierEnum,
    BEDLINKER: 'BEDLINKER' as SupplierEnum,
    VINPEARL: 'VINPEARL' as SupplierEnum,
    AGODA: 'AGODA' as SupplierEnum
  };
}
