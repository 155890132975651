import { Component, Inject, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import {
  BookingInfo,
  BookingTransactionInfo,
  GroupBooking
} from '@gtd/b2c-client';

@Component({
  selector: 'gtd-booking-result-combo',
  templateUrl: './booking-result-combo.component.html',
  styleUrls: ['./booking-result-combo.component.scss']
})
export class BookingResultComboComponent implements OnInit {
  @Input() allFinalBookingDetail: GroupBooking;
  hotelDetail;
  airDetail;
  appName: string = '';
  constructor(@Inject('appName') appName: string) {
    if (appName) {
      this.appName = appName;
    }
  }

  counter = Array;

  ngOnInit() {
    this.hotelDetail = this.allFinalBookingDetail.bookingInfo.transactionInfos.find(
      info => info.supplierType === 'HOTEL'
    );
    this.airDetail = this.allFinalBookingDetail.bookingInfo.transactionInfos.find(
      info => info.supplierType === 'AIR'
    );
  }
  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}
