import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AVAILABLEPLANS_FEATURE_KEY,
  AvailablePlansState
} from './available-plans.reducer';

// Lookup the 'AvailablePlans' feature state managed by NgRx
const getAvailablePlansState = createFeatureSelector<AvailablePlansState>(
  AVAILABLEPLANS_FEATURE_KEY
);

const getLoaded = createSelector(
  getAvailablePlansState,
  (state: AvailablePlansState) => state.loaded
);
const getError = createSelector(
  getAvailablePlansState,
  (state: AvailablePlansState) => state.error
);

const getAllAvailablePlans = createSelector(
  getAvailablePlansState,
  getLoaded,
  (state: AvailablePlansState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getAvailablePlansState,
  (state: AvailablePlansState) => state.selectedId
);
const getSelectedAvailablePlans = createSelector(
  getAllAvailablePlans,
  getSelectedId,
  (availablePlans, id) => {
    return availablePlans ? Object.assign({}, availablePlans) : undefined;
  }
);

export const availablePlansQuery = {
  getLoaded,
  getError,
  getAllAvailablePlans,
  getSelectedAvailablePlans
};
