import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { HotelFacade, PopularPlacesFacade } from '@gtd/meta';
import { SearchKeywordElement } from '@gtd/b2c-client';
import { MatAutocompleteTrigger, MatFormFieldControl } from '@angular/material';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ComboSearchService } from '../combo-search.service';
import { SearchKeywordFacade } from '../../../state/search-keyword/search-keyword.facade';
import { ComboVJAutocompleteSearchKeywordFacade } from '../../../state/autocomplete-search-keyword/autocomplete-search-keyword.facade';
import { tap } from 'rxjs/operators';
import { AirportDTO } from '@gtd/meta-client';

export interface InputField {
  stateCtrl: string;
  hotelName: string;
}

@Component({
  selector: 'gtd-hotel-auto-complete',
  templateUrl: './hotel-auto-complete.component.html',
  styleUrls: ['./hotel-auto-complete.component.scss'],
  providers: [
    HotelFacade,
    {
      provide: MatFormFieldControl,
      useExisting: HotelAutoCompleteComponent,
      multi: true
    }
  ]
})
export class HotelAutoCompleteComponent
  implements OnInit, MatFormFieldControl<InputField>, OnDestroy {
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatAutocompleteTrigger, { static: false })
  autoSearchKeyword: MatAutocompleteTrigger;
  @Input() label = 'Label';
  @Input() placeholder = 'Placeholder';
  @Input() iconLeft?: string;
  @Input() type: string;
  @Input() propertyName = '';
  @Input() newToLocation: any; // TODO
  @Input() findNewHotelPlace: boolean;
  @Input() dataLocalStorages?: AirportDTO;
  @Input() dataSearchCombo?: any;
  form: FormGroup;
  focused = false;
  isShiftFocus: boolean;

  private _disabled = false;

  stateCtrl = new FormControl();
  hotelName = new FormControl();
  stateChanges = new Subject<void>();

  groupSearchLabel: string = 'destination-popular';

  destinationDTOs: any;

  @HostBinding() id = 'gtd-auto-compete-${Test.nextId++}';
  @HostBinding('attr.aria-describedby') describedBy = '';

  private _required = false;

  errorState = false;

  allSearchKeyword$ = this.searchKeywordFacade.allSearchKeyword$;
  allPopularPlaces$ = this.popularPlacesFacade.allPopularPlaces$;

  constructor(
    fb: FormBuilder,
    @Optional() @Self() public ngControl: NgControl,
    private elRef: ElementRef<HTMLElement>,
    private comboSearchService: ComboSearchService,
    private searchKeywordFacade: SearchKeywordFacade,
    private popularPlacesFacade: PopularPlacesFacade,
    private autocompleteSearchKeywordFacade: ComboVJAutocompleteSearchKeywordFacade
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.destinationDTOs = this.comboSearchService.getAllLocalStorageLocation(
      this.type
    );
    this.popularPlacesFacade.getPopularDestination('FAVOURITE_HOTEL_LOCATION');
    this.form = fb.group({
      stateCtrl: '',
      hotelName: ''
    });
  }

  keyEnter() {
    if (!this.isShiftFocus) {
      this.changed.emit({
        submit: true
      });
    }
  }
  shiftFocus(isOpen) {
    if (isOpen) {
      this.isShiftFocus = true;
    } else {
      this.isShiftFocus = false;
    }
  }

  ngOnInit() {
    this.destinationDTOs = this.comboSearchService.getAllLocalStorageLocation(
      this.type
    );
    if (
      this.destinationDTOs &&
      this.destinationDTOs.location &&
      this.destinationDTOs.location.searchCode
    ) {
      this.stateCtrl.setValue(this.destinationDTOs.location);
    } else if (this.dataLocalStorages) {
      this.stateCtrl.setValue({
        name: this.dataLocalStorages.city,
        propertyCount: null,
        searchCode: this.dataLocalStorages.city,
        searchType: 'AUTO',
        supplier: 'EXPEDIA'
      });
    }

    this.stateCtrl.valueChanges.subscribe((data: any) => {
      if (!data || typeof data === 'string') {
        this.searchKeywordFacade.loadAll(data, 'vi', 0, 15);
      }
      if (typeof data === 'object') {
        this.searchKeywordFacade.loadAll('', 'vi', 0, 15);
      }
      if (data || data !== '') {
        this.groupSearchLabel = 'list-destination';
        if (!data.searchCode) {
          let searchData = {
            name: data,
            propertyCount: null,
            searchCode: data,
            searchType: 'AUTO',
            supplier: 'EXPEDIA'
          };
          this.onTouched();
          this.onChange(searchData);
        } else {
          this.onChange(data);
          this.onTouched();
        }
      }
    });
    if(!this.findNewHotelPlace || !this.destinationDTOs || !this.destinationDTOs.location || !this.destinationDTOs.location.searchCode) {
      this.newToLocation.subscribe(data => {
        if (data) {
          this.stateCtrl.patchValue(data);
        }
        //   console.log(data);
      });
    }
  }
  listenChangeValue(value) {}
  displayFn(hotel?: SearchKeywordElement): string | undefined {
    return hotel ? `${hotel.name}` : undefined;
  }

  @Input()
  get value(): InputField {
    let n = this.form.value;
    return n;
  }
  set value(sf: InputField) {
    // setTimeout(() => {
    //   this.setDefaultHotel(sf);
    // }, 100);
  }
  //   setDefaultHotel = sf => {
  //     setTimeout(() => {
  //       let getHotelLocation = this.comboSearchService.getAllLocalStorageLocation(
  //         'to'
  //       );
  //       console.log(getHotelLocation, 'test');
  //       if (getHotelLocation) {
  //         console.log('ok nek');
  //         this.stateCtrl.setValue({
  //           name: getHotelLocation.city,
  //           propertyCount: null,
  //           searchCode: getHotelLocation.city,
  //           searchType: 'AUTO',
  //           supplier: 'EXPEDIA'
  //         });
  //       }
  //       // this.form.patchValue({ stateCtrl: hotelLocation[0].code, hotelName: hotelLocation[0].name });
  //       this.stateChanges.next();
  //     });
  //   };

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  getOptionSelect(value: SearchKeywordElement) {
    this.autocompleteSearchKeywordFacade.addSearchKeywordHotel([value]);
    setTimeout(() => {
      this.isShiftFocus = false;
    }, 200);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  getOptionSelectPopular(value: string) {
    this.form.patchValue({ hotelName: value });
    let searchData = {
      name: value,
      propertyCount: null,
      searchCode: value,
      searchType: 'AUTO',
      supplier: 'EXPEDIA'
    };
    this.onTouched();
    this.onChange(searchData);
  }

  get empty() {
    let n = this.form.value;
    return !n.area && !n.exchange && !n.subscriber;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
