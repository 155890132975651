import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LowestPriceFlightsFacade} from '../flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import {FlightFilterFacade} from '../flight-search/flight-filter/+state/flight-filter.facade';
import {fadeIn} from '@gtd/helpers';
import {environment} from '@env/environment';

@Component({
  selector: 'gtd-filter-panel-combo',
  templateUrl: './filter-panel-combo.component.html',
  styleUrls: ['./filter-panel-combo.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated,
  animations: [fadeIn]
})
export class FilterPanelComboComponent implements OnInit {
  @Input() disableAirline: boolean;
  @Input() isLoading: boolean;
  priceFilter = null;
  classTypes: BranchFilter[] = [
    { branchCode: 'PROMO', branchName: 'promo', checked: false },
    { branchCode: 'ECONOMY', branchName: 'economy', checked: false },
    { branchCode: 'PREMIUM', branchName: 'premium', checked: false },
    { branchCode: 'BUSINESS', branchName: 'business', checked: false },
    { branchCode: 'FIRST', branchName: 'first', checked: false }
  ];

  conditionTypes: BranchFilter[] = [
    {
      branchCode: 'true',
      branchName: 'allowed-refund-cancellation',
      checked: false
    },
    {
      branchCode: 'false',
      branchName: 'no-refunds-cancellations',
      checked: false
    }
  ];

  timeFilter: BranchFilter[] = [
    { branchCode: '-6', branchName: '00:00 - 06:00', checked: false, brandLogo: 'sun-rise' },
    { branchCode: '+6-12', branchName: '06:00 - 12:00', checked: false, brandLogo: 'morning' },
    { branchCode: '+12-18', branchName: '12:00 - 18:00', checked: false, brandLogo: 'sun-shine' },
    { branchCode: '+18', branchName: '18:00 - 24:00', checked: false, brandLogo: 'night' }
  ];

  allLowestPriceFlights$ = this.lowestPriceFlightsFacade.allLowestPriceFlights$;
  loaded$ = this.lowestPriceFlightsFacade.loaded$;
  selectedAirlineOptions$ = this.flightFilterFacade.selectedAirlineOptions$;
  selectedCabinClassOptions$ = this.flightFilterFacade
    .selectedCabinClassOptions$;
  selectedTicketPolicyOptions$ = this.flightFilterFacade
    .selectedTicketPolicyOptions$;
  selectedArrivalDateTimeReturnOptions$ = this.flightFilterFacade
    .selectedArrivalDateTimeReturnOptions$;
  selectedArrivalDateTimeOptions$ = this.flightFilterFacade
    .selectedArrivalDateTimeOptions$;
  panelOpenState: boolean = true;

  constructor(
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade
  ) {}

  ngOnInit() {
    this.flightFilterFacade.currentFilter$.subscribe(filter => {
      this.priceFilter = {
        min: filter.filterFromPrice,
        max: filter.filterToPrice
      };
    })
  }

  onAirlineOptionsChanged(event: string[]) {
    this.isLoading = true;
    this.flightFilterFacade.changeAirlineOptions(event);
    setTimeout(() => {
      this.isLoading = false;
    }, 100)
  }

  onCabinClassOptionsChanged(event: string[]) {
    this.isLoading = true;
    this.flightFilterFacade.changeCabinClassOptions(event);
    setTimeout(() => {
      this.isLoading = false;
    }, 100)
  }

  onTicketPolicyOptionsChanged(event: string[]) {
    this.isLoading = true;
    this.flightFilterFacade.changeTicketPolicyOptions(event);
    setTimeout(() => {
      this.isLoading = false;
    }, 100)
  }

  onTicketPriceChanged(event: string[]) {
    this.isLoading = true;
    this.flightFilterFacade.changePrice(this.priceFilter.min, this.priceFilter.max);

  }

  onArrivalDateTimeOptions(event: string[]) {
    this.isLoading = true;
    this.flightFilterFacade.changeDepartureDateTimeOptions(event);
    setTimeout(() => {
      this.isLoading = false;
    }, 100)
  }

  onArrivalDateTimeReturnOptions(event: string[]) {
    this.isLoading = true;
    this.flightFilterFacade.changeArrivalDateTimeReturnOptions(event);
    setTimeout(() => {
      this.isLoading = false;
    }, 100)
  }
}
export interface BranchFilter {
  brandLogo?: string;
  lowestPrices?: number;
  branchCode?: string;
  branchName?: string;
  checked: boolean;
}
